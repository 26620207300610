import React from "react";
import "./Page_partplmone.css";
function Page_partplmone({ title, body, imgurl, classtitle,imgurl_img ,Id_IMG,IDLE_IMG,imgurlid}) {
  return (
    <div className="sectionplmone">
      <div className="image-sectionplmone">
        <img id={Id_IMG} src={imgurl} alt="" />
        <img id={IDLE_IMG} src={imgurlid} alt="" />
      </div>
      <div className="content-sectionplmone">

        <h1 className={classtitle}> {title}</h1>

        <p>{body}</p>
    
      </div>
      
      
  
    
    </div>
    
  );
}

export default Page_partplmone;
