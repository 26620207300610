import React, { useEffect, useState } from "react";
import imgurlEasy from "../image/Aerospace&Defence.svg";
import imgurlsource from "../image/Aerospace&Defence.svg";
import imgurlWorkflow from "../image/Aerospace&Defence.svg";
import imgurlEasyPD from "../image/Pharmaceuticals.svg";
import imgurlsourcePD from "../image/Pharmaceuticals.svg";
import imgurlWorkflowPD from "../image/Pharmaceuticals.svg";
import imgurlWorkflowM from "../image/Aerospace&Defence.svg";
import img2 from "../image/Aerospace&Defence_img.svg";
import img3 from "../image/Pharmaceuticals_img.svg";
import Page_Discretelndustries1 from "../Page_DiscreteIndustries1";
import Page_Discretelndustries2 from "../Page_DiscreteIndustries2";
import Page_Discretelndustries4 from "../Page_Discretelndustries4";
import Pharmaceuticals from "../../Component/image/mobileImages/Pharmaceuticals.svg"
import AerospaceDefence from "../../Component/image/mobileImages/AerospaceDefence.svg"


const Plm_fourone_Main_file = () => {

    const [screenWidth, setScreenWitdh] = useState(true)
    useEffect(() => {
        if (window.screen.width < 430) { setScreenWitdh(!screenWidth) }
    }, [])



    return (
        <div>
            {
                screenWidth ? <div>
                    <Page_Discretelndustries1
                        title={
                            <>
                                <span className="boldSpan">P</span>rocess{" "}
                                <span className="boldSpan">I</span>ndustries
                            </>
                        }
                        body={
                            "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                        }
                    />

                    <Page_Discretelndustries4
                        imgurl1={img3}
                        title={
                            <>
                                <span className="boldSpan">P</span>harmaceuticals{" "}
                            </>
                        }
                        imgurlonepD={imgurlEasyPD}
                        imgtext1={
                            "The food and beverage industry manages enormous amounts of data related to ingredients, formulas, test and trials, as well as intense competition and other challenges during the process."
                        }
                        imgurltwopD={imgurlsourcePD}
                        imgtext2={
                            "With ActionPLM, you can manage supplier collaboration and communication, speed up the R & D process, ensure compliance and other data from different departments. "
                        }
                        imgurlthreepD={imgurlWorkflowPD}
                        imgtext3={
                            "ActionPLM makes it easier to produce new products by solving these issues in a timely and efficient manner on a daily basis."
                        }
                        colortitle="darkgreen"
                    />
                </div> :
                    <div>
                        <div>
                            <Page_Discretelndustries1
                                title={
                                    <>
                                        <span className="boldSpan">P</span>rocess{" "}
                                        <span className="boldSpan">I</span>ndustries
                                    </>
                                }
                                body={
                                    "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                                }
                            />
                        </div>
                        <div>
                            <img src={Pharmaceuticals} alt="img" width="100%" height="100%" />
                        </div>
                    </div>
            }
            {
                screenWidth ? <div>
                    <Page_Discretelndustries1
                        title={
                            <>
                                <span className="boldSpan">D</span>iscrete{" "}
                                <span className="boldSpan">I</span>ndustries
                            </>
                        }
                        body={
                            "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                        }
                    />

                    <Page_Discretelndustries2
                        title={
                            <>
                                <span className="boldSpan">A</span>erospace {" "}
                                <span className="boldSpan">& </span> {" "}
                                <span className="boldSpan">  D</span>efence {" "}
                            </>
                        }
                        imgurlone={imgurlEasy}
                        imgtext1={
                            "ActionPLM platform streamlines product design,manufacturing and maintenance for continuous information and communication exchange."
                        }
                        imgurltwo={imgurlsource}
                        imgtext2={
                            "We help you in connecting your systems together so that you have the connectivity and accessbility required."
                        }
                        imgurlthree={imgurlWorkflow}
                        imgtext3={
                            "Organisations in the aerospace and defence industries are developing high-performance platforms and systems to manage new technology, propulsion engines and other hardware tech. "
                        }
                        imgurlfour={imgurlWorkflowM}
                        imgtext4={
                            "ActionPLM helps you in improving your infrastructure and security with your state-of-the-art platform capabilities."
                        }
                        imgurl={img2}
                        colorname="brown"
                        iD_name="id_name"
                        classpara="class_para"
                        idpara="id_icon"
                        id_para="id_paraicon"
                    />
                </div> :
                    <div>
                        <div>
                            <Page_Discretelndustries1
                                title={
                                    <>
                                        <span className="boldSpan">D</span>iscrete{" "}
                                        <span className="boldSpan">I</span>ndustries
                                    </>
                                }
                                body={
                                    "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                                }
                            />
                        </div>
                        <div>
                            <img src={AerospaceDefence} alt="img" width="100%" height="100%" />
                        </div>
                    </div>

            }
        </div>
    );
}
export default Plm_fourone_Main_file;
