/*import React from "react"*/
import React from "react";

import img1 from "../image/IntegrationManagement_img.svg";
import imgurlid1 from "../image/Integration_Management_img.svg";
import Integrationimg from "../image/Integration_Management_img.svg";
import img2 from "../image/Ingredient_Management_imges.svg";
import imgurlEasy from "../image/Ingredient_Management_Easy.svg";
import imgurlsource from "../image/Ingredient_Management_source.svg";
import imgurlWorkflow from "../image/Ingredient_Management_Workflow.svg";

import imgone from "../image/Rectangle10422_img.svg";
import imgtwo from "../image/Polygon.svg";
import imgCRM from "../image/CRM.svg";
import imgERP from "../image/ERP.svg";
import imgCAD from "../image/CAD.svg";
import imgOTHER from "../image/OTHERS.svg";
import Page_partplmone from "../Page_partplmone";
import Page_partplmtwo from "../Page_partplmtwo";
import Page_partplmthree from "../Page_partplmthree";
import Page_partplmfour from "../Page_partplmfour";




function Plm_threeone_Main_file  ()  {

  
  
  return (
    <div>
      <Page_partplmone
        title={
          <>
            <span className="boldSpanone">I</span>ntegration &nbsp; {"    "}
            <span className="boldSpanone">M</span>anagement
          </>
        }
        body={
          "ActionPLM improves process efficiency and productivity with its seamless integration by providing a comprehensive perspective towards the product life cycle across all key disciplines ensuring that the data is visible and up to date across all platforms."
        }
        imgurl={img1}
        imgurlid={imgurlid1}
        classtitle="title2"
        imgurl_img="Integrationimg"
        Id_IMG="Integration_Management"
        IDLE_IMG="Integration_Management1"
      />
      <Page_partplmtwo
        imgurlone={imgurlEasy}
        imgtext1={"Easy Integration"}
        classnamepara1="parafirst"
        imgurltwo={imgurlsource}
        imgtext2={"Access Data From Different Sources"}
        imgurlthree={imgurlWorkflow}
        imgtext3={"Automated Workflow"}
        classnamepara="para1"
        classnamepara2="para2"
        imgurl={img2}
        imgurl_img={Integrationimg}
        classnameicon="icon2"
        classnameicon1="classnameicon_img"
        classnameicon2="img_classnameicon"
        section_text="section-text"
        secation_Part="secation-Part"
      />
      <Page_partplmthree
        title={
          <>
            <h1>
              Frictionless Enterprise
              <span className="boldSpanone"> Integration</span>
            </h1>
          </>
        }
        imgurlone={imgone}
        imgurltwo={imgtwo}
        imgurlCRM={imgCRM}
        imgurlERP={imgERP}
        imgurlCAD={imgCAD}
        imgurlOTHER={imgOTHER}
        CRM_IMG="CRM_IMGS"
        id_box="text_div"
        box="id_box"
        IMG_CRM="IMG_CRMS"
        CAD_IMG="CAD_IMGS"
        search_box="search_div"
      />
     
    </div>
  );
}

export default Plm_threeone_Main_file;
