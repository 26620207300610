import React, { useState, useEffect } from 'react';

const LineAnimation = () => {
    const [L1, setL1] = useState(0);
    const [L2, setL2] = useState(0);
    const [L3, setL3] = useState(0);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPercentage =
                (document.documentElement.scrollTop + document.body.scrollTop) /
                (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            setL1(Math.floor(scrollPercentage * 150))
            setL2(Math.floor(scrollPercentage * 310))
            setL3(Math.floor(scrollPercentage * 480))
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <svg width="27" height="460" viewBox="0 0 27 498" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "45%" }} id="progressLine">
            <path d="M4.65787 21.9746C-0.123899 17.1928 -0.123901 9.44001 4.65786 4.65825C9.43963 -0.123517 17.1924 -0.123514 21.9742 4.65825C26.7559 9.44002 26.7559 17.1928 21.9742 21.9746C17.1924 26.7563 9.43963 26.7563 4.65787 21.9746Z" stroke="#387FF1" stroke-miterlimit="10" />
            <path d="M13.3482 17.8006C15.8992 17.8006 17.9399 15.7599 17.9399 13.2089C17.9399 10.658 15.8992 8.61719 13.3482 8.61719C10.7973 8.61719 8.75649 10.658 8.75649 13.2089C8.75649 15.7599 10.7973 17.8006 13.3482 17.8006Z" fill="url(#paint0_linear_237_15637)" />
            <path d="M13.3484 15.2495C14.4963 15.2495 15.3892 14.3567 15.3892 13.2087C15.3892 12.0608 14.4963 11.168 13.3484 11.168C12.2005 11.168 11.3076 12.0608 11.3076 13.2087C11.3076 14.3567 12.2005 15.2495 13.3484 15.2495Z" fill="#282727" />
            <path d={`M13.5 42L13.5 ${L1}`} stroke="#AAACB4" stroke-miterlimit="10" id='firstLine' className='firstLine' style={{ transition: 'all 0.3s linear' }} />
            <path d="M1.82473 173.196C0.264624 166.616 4.33397 160.018 10.9139 158.458C17.4938 156.897 24.0926 160.967 25.6527 167.547C27.2128 174.127 23.1435 180.725 16.5635 182.286C9.98363 183.846 3.38484 179.776 1.82473 173.196Z" stroke="#387FF1" stroke-miterlimit="10" />
            <path d="M13.7054 174.951C16.2564 174.951 18.2971 172.91 18.2971 170.359C18.2971 167.808 16.2564 165.767 13.7054 165.767C11.1544 165.767 9.11367 167.808 9.11367 170.359C9.11367 172.91 11.1544 174.951 13.7054 174.951Z" fill="url(#paint1_linear_237_15637)" />
            <path d="M13.7056 172.4C14.8535 172.4 15.7463 171.507 15.7463 170.359C15.7463 169.211 14.8535 168.318 13.7056 168.318C12.5576 168.318 11.6648 169.211 11.6648 170.359C11.6648 171.507 12.5576 172.4 13.7056 172.4Z" fill="#282727" />
            <path d={`M13.5 199L13.5 ${L2}`} stroke="#AAACB4" stroke-miterlimit="10" />
            <path d="M4.65813 335.975C-0.12364 331.193 -0.123663 323.44 4.6581 318.658C9.43987 313.876 17.1926 313.876 21.9744 318.658C26.7562 323.44 26.7562 331.193 21.9744 335.975C17.1926 340.756 9.43989 340.756 4.65813 335.975Z" stroke="#387FF1" stroke-miterlimit="10" />
            <path d="M13.335 331.797C15.886 331.797 17.9268 329.757 17.9268 327.206C17.9268 324.655 15.886 322.614 13.335 322.614C10.7841 322.614 8.74331 324.655 8.74331 327.206C8.74331 329.757 10.7841 331.797 13.335 331.797Z" fill="url(#paint2_linear_237_15637)" />
            <path d="M13.335 329.247C14.4829 329.247 15.3757 328.354 15.3757 327.206C15.3757 326.058 14.4829 325.165 13.335 325.165C12.187 325.165 11.2942 326.058 11.2942 327.206C11.2942 328.354 12.187 329.247 13.335 329.247Z" fill="#282727" />
            <path d={`M13.5 356L13.5 ${L3}`} stroke="#AAACB4" stroke-miterlimit="10" />
            <path d="M1.82475 487.564C0.264644 480.984 4.33397 474.385 10.9139 472.825C17.4938 471.265 24.0926 475.334 25.6527 481.914C27.2128 488.494 23.1435 495.093 16.5635 496.653C9.98363 498.213 3.38486 494.144 1.82475 487.564Z" stroke="#387FF1" stroke-miterlimit="10" />
            <path d="M13.6832 489.304C16.2341 489.304 18.2749 487.264 18.2749 484.713C18.2749 482.162 16.2341 480.121 13.6832 480.121C11.1322 480.121 9.09145 482.162 9.09145 484.713C9.09145 487.264 11.1322 489.304 13.6832 489.304Z" fill="url(#paint3_linear_237_15637)" />
            <path d="M13.6834 486.753C14.8313 486.753 15.7241 485.86 15.7241 484.713C15.7241 483.565 14.8313 482.672 13.6834 482.672C12.5354 482.672 11.6426 483.565 11.6426 484.713C11.6426 485.86 12.5354 486.753 13.6834 486.753Z" fill="#282727" />
            <defs>
                <linearGradient id="paint0_linear_237_15637" x1="16.5614" y1="10.0803" x2="10.1493" y2="16.4924" gradientUnits="userSpaceOnUse">
                    <stop offset="0.187" stop-color="#387FF1" />
                    <stop offset="1" stop-color="#1149A2" />
                </linearGradient>
                <linearGradient id="paint1_linear_237_15637" x1="16.9186" y1="167.175" x2="10.5065" y2="173.587" gradientUnits="userSpaceOnUse">
                    <stop offset="0.187" stop-color="#387FF1" />
                    <stop offset="1" stop-color="#144DA9" />
                </linearGradient>
                <linearGradient id="paint2_linear_237_15637" x1="16.5482" y1="324.093" x2="10.1361" y2="330.505" gradientUnits="userSpaceOnUse">
                    <stop offset="0.187" stop-color="#387FF1" />
                    <stop offset="1" stop-color="#0F49A7" />
                </linearGradient>
                <linearGradient id="paint3_linear_237_15637" x1="16.8964" y1="481.545" x2="10.4843" y2="487.957" gradientUnits="userSpaceOnUse">
                    <stop offset="0.187" stop-color="#387FF1" />
                    <stop offset="1" stop-color="#0549B6" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LineAnimation;
