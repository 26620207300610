import React from 'react'
import { Link } from 'react-router-dom'

const ServiceSection = () => {
    return (
        <div className='Service-Section'>
            <div className='ServiceInner'>
                <h4>Service</h4>
                <ul>
                    <li><Link to="/Bom-Configuration">BOM Configuration</Link> </li>
                    <li><Link to="/File-Management">File Management</Link> </li>
                    <li><Link to="/Workflow-Management">Workflow Management</Link> </li>
                    <li><Link to="/Permission-based-access">Permission Based Access</Link> </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSection