import React, { useState } from 'react'
import "./navBar.css"
import icon from "../image/logo.svg"
import AreoSpace from "../image/DDIcon/AeroSpace.svg"
import Automotive from "../image/DDIcon/Automotive.svg"
import Cosmetics from "../image/DDIcon/Cosmetics.svg"
import ElectricVehicles from "../image/DDIcon/ElectircVehicles.svg"
import Foodbeverages from "../image/DDIcon/Foodbeverages.svg"
import pharmaCeuticals from '../image/DDIcon/pharmaCeuticals.svg'
import Industrial from '../image/DDIcon/Industrial.svg'
import Textile from "../image/DDIcon/Textile.svg"


const DropDown = ({ onClick }) => {
    var url = window.location.href;
    var page_ID = url.substring(url.lastIndexOf('/') + 1);
    const [page, setpage] = useState(page_ID)

    return (
        <div className="DropDown">
            <div className='DropDownCard'>
                <div className='content_items'>
                    <div className='content_item'>
                        <div className='content-heading'>Discrete PLM</div>
                        <div className='list_drop'>
                            <div className={`itemContent ${page === "Industries#Automotive" || page === "Automotive" ? 'activehover' : ''} `}>
                                <div className='iconCricle'>
                                    <img src={Automotive} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href='#Automotive' onClick={onClick} >Automotive</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Industrial-Manufacturing" || page === "Industrial-Manufacturing" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={Industrial} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Industrial-Manufacturing" onClick={onClick}>Industrial Manufacturing</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Aerospace-Defence" || page === "Aerospace-Defence" ? 'activehover' : ''} `}>
                                <div className='iconCricle'>
                                    <img src={AreoSpace} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Aerospace-Defence" onClick={onClick}>Aerospace & Defence</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Electric-Vehicles" || page === "Electric-Vehicles" ? 'activehover' : ''} `}>
                                <div className='iconCricle'>
                                    <img src={ElectricVehicles} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Electric-Vehicles" onClick={onClick}>Electric Vehicles</a>
                                </div>
                            </div>
                        </div>
                        <div className='content-heading'>Process</div>
                        <div className='list_drop'>
                            <div className={`itemContent ${page === "Industries#Food-beverages" || page === "Food-beverages" ? 'activehover' : ''} `}>
                                <div className='iconCricle processIcon'>
                                    <img src={Foodbeverages} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Food-beverages" onClick={onClick}>Food and Beverages</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Cosmetics" || page === "Cosmetics" ? 'activehover' : ''} `}>
                                <div className='iconCricle processIcon'>
                                    <img src={Cosmetics} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Cosmetics" onClick={onClick}>Cosmetics</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Pharmaceuticals" || page === "Pharmaceuticals" ? 'activehover' : ''} `}>
                                <div className='iconCricle processIcon'>
                                    <img src={pharmaCeuticals} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Pharmaceuticals" onClick={onClick}>Pharmaceuticals</a>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Industries#Textile" || page === "Textile" ? 'activehover' : ''} `}>
                                <div className='iconCricle processIcon'>
                                    <img src={Textile} alt='icon' width="23px" height="23px" />
                                </div>
                                <div className='item-title'>
                                    <a href="#Textile" onClick={onClick}>Textile</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DropDown