import React from "react";
import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
import img1 from "../image/change_managment_img.svg";

import box from "../image/change_managment_card_one.svg";
import Req_two from "../image/change_managment_card_two.svg";
import Req_three from "../image/change_managment_card_three.svg";

import Group_one from "../image/change_managment_multi-Level-img.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/change_managment_cost_Rollup_img_.svg";
import Group_bell from "../image/change_managment_Marup_img.svg";
function plm_three_Main_file() {
  const svgFrt = (
    
    <svg
      className="svgthree"
      width="921"
      height="1374"
      viewBox="0 0 921 1374"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none"
      stroke="#5C90C4"
      stroke-linejoin="round"
      stroke-miterlimit="40"
      stroke-width="4"
               
              className="path2"
      d="M438 103.266C335 -127.235 -202.623 76.2563 86.3772 310.257C140.759 354.289 325.452 402.702 656.378 191.757C956 0.766647 964.25 226.297 851 281.767C656 377.277 -44.5 506.5 169 720C369.621 920.621 707.5 713.5 799.5 785.5C891.5 857.5 18.5 1003.5 511 1372.27" />
      <path className="dashed2"
      fill="none"
      stroke="white"
      stroke-linejoin="round"
      stroke-miterlimit="40"
      stroke-width="4"
      
      d="M438 103.266C335 -127.235 -202.623 76.2563 86.3772 310.257C140.759 354.289 325.452 402.702 656.378 191.757C956 0.766647 964.25 226.297 851 281.767C656 377.277 -44.5 506.5 169 720C369.621 920.621 707.5 713.5 799.5 785.5C891.5 857.5 18.5 1003.5 511 1372.27" />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page3_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page3_p2").style.display = "block";
  }, 2000);

  setTimeout(() => {
    document.querySelector(".Page3_p3").style.display = "block";
  }, 3200);
  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">C</span>hange{" "}
            <span className="boldSpan">M</span>anagement
          </>
        }
        body={
          "Industries are always concerned about changes because it affects the whole process of the industry and also affects the cost. ActionPLM offers a change management module where you can manage, track and also apply markup for the changes."
        }
        imgurl={img1}
        changemanagmentimg="imgchangemag"
        changemanagmentimg1="changemanagment_img"

       
      />
      <Page2
        classes={["Page3_p1", "Page3_p2", "Page3_p3"]}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
        svgFrt={svgFrt}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Multi-Level Approval Process"}
        bottom_two_P={"Cost Rollup Management"}
        bottom_three_P={"Markup    Management"}
        fved="group_bell_img"
        fved_group="IMG_management"
      />
    </div>
  );
}

export default plm_three_Main_file;
