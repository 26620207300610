import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "./keybenefits.css"
import percentageBar from "../../image/percentageBar.svg";

const Keybenefits = () => {

  return (
    <div className='section_benefit'>
      <div className='key_benefit'>
        <h2>Key <span className='keybnf'>benefits</span></h2>
        <p className='mt-4'>ActionPLM contributes to boosting the value of a product.</p>
      </div>
      <Container id='PER100'>
        <Row>
          <Col lg="3" md="4">
            <div className='percentage_ marigntopOdd' id='per100'>
              <div className={`cricleMain`}>
                <div className='percentage_cricle'>
                  <div className='percentage_cricleInner'>
                    <div id="digits">100%</div>
                  </div>
                </div>
                <svg width="180" height="180" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="paint0_linear_219_9377" x1="90.022" y1="179.798" x2="90.022" y2="0.289223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#3183EE" />
                      <stop offset="1" stop-color="#56CBF2" />
                    </linearGradient>
                  </defs>
                  <circle className='boxshow' cx="80" cy="80" r="70" stroke-linecap="square" />
                </svg>
              </div>
              <div className='percentage_imgbox'>
                <img src={percentageBar} alt="percentageBar" />
                <div className='contentBox'>
                  <div className='contentBoxInner border-gradient_100per'>
                    <p>
                      Remove Part Duplication
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="3" md="4">
            <div className='percentage_' id='per50'>
              <div className='percentage_imgbox_reverse' id='per50_mobile'>
                <div className='contentBox'>
                  <div className='contentBoxInner border-gradient_50per' id='RCF'>
                    <p> Resolve Customer Issues Faster</p>
                  </div>
                </div>
                <img src={percentageBar} alt="percentageBar" />
              </div>
              <div className='cricleMain_even'>
                <div className='cricleMain criclegradient50'>
                  <div className='percentage_cricle'>
                    <div className='percentage_cricleInner'>
                      <div id="digits">50%</div>
                    </div>
                  </div>
                  <svg width="180" height="180" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="paint0_linear_219_9389" x1="0.768708" y1="0.874023" x2="0.768708" y2="180.383" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#9D1B67" />
                        <stop offset="1" stop-color="#F852C4" />
                      </linearGradient>
                    </defs>
                    <circle className='boxshow' cx="80" cy="80" r="70" stroke-linecap="square" />
                  </svg>
                </div>
              </div>
              <div className='percentage_imgbox percentage_imgbox_reverse_d'>
                <img src={percentageBar} alt="percentageBar" />
                <div className='contentBox'>
                  <div className='contentBoxInner border-gradient_50per'>
                    <p> Resolve Customer Issues Faster</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="3" md="4">
            <div className='percentage_ marigntopOdd' id='per35'>
              <div className='cricleMain criclegradient35'>
                <div className='percentage_cricle'>
                  <div className='percentage_cricleInner'>
                    <div id="digits">35%</div>
                  </div>
                </div>
                <svg width="180" height="180" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="paint0_linear_219_9397" x1="0.467243" y1="0.478516" x2="0.467227" y2="179.987" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#D04318" />
                      <stop offset="1" stop-color="#F8B633" />
                    </linearGradient>
                  </defs>
                  <circle cx="80" cy="80" r="70" stroke-linecap="square" />
                </svg>
              </div>
              <div className='percentage_imgbox'>
                <img src={percentageBar} alt="percentageBar" />
                <div className='contentBox'>
                  <div className='contentBoxInner border-gradient_35per'>
                    <p className='PFM'>Bring Products Faster To Market</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="3" md="4">
            <div className='percentage_' id='per25'>
              <div className='percentage_imgbox_reverse' id='per25_mobile'>
                <div className='contentBox smallContentBoxInner'>
                  <div className='contentBoxInner  border-gradient_25per'>
                    <p>
                      Increase <br />Profit
                    </p>
                  </div>
                </div>
                <img src={percentageBar} alt="percentageBar" />
              </div>
              <div className='cricleMain_even'>
                <div className='cricleMain criclegradient25'>
                  <div className='percentage_cricle'>
                    <div className='percentage_cricleInner'>
                      <div id="digits">25%</div>
                    </div>
                  </div>
                  <svg width="180" height="180" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="paint0_linear_219_9405" x1="0.262011" y1="0.874023" x2="0.262011" y2="180.383" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3D319C" />
                        <stop offset="1" stop-color="#C50FBE" />
                      </linearGradient>
                    </defs>
                    <circle cx="80" cy="80" r="70" stroke-linecap="square" />
                  </svg>
                </div>
              </div>
              <div className='percentage_imgbox percentage_imgbox_reverse_d'>
                <img src={percentageBar} alt="percentageBar" />
                <div className='contentBox smallContentBoxInner'>
                  <div className='contentBoxInner border-gradient_25per'>
                    <p> Increase <br />Profit</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Keybenefits