import React from 'react'
import LogoSection from "./LogoSection"
import "./Footer.css";
import ServiceSection from './ServiceSection';
import CompanySection from './CompanySection';
import GetInTouch from './GetInTouch'
const Footer = () => {
    return (
        <div className='Footer-main'>
            <div className='boxContainer'>
                <div className='boxContainerInner'></div>
                <div className='boxShadow'></div>
            </div>
            <div className='Footer'>
                <div className='FristSection'>
                    <LogoSection />
                </div>
                <div className='SecondSection'>
                    <ServiceSection />
                </div>
                <div className='ThridSection'>
                    <CompanySection />
                </div>
                <div className='FourthSection'>
                    <GetInTouch />
                </div>
            </div>
            <div className='copyright'>
                <div> <i class="fa-regular fa-copyright"></i>&nbsp; ActionPLM 2022, ALL RIGHT RESERVED</div>
            </div>
        </div>
    )
}

export default Footer