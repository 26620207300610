import React, { useEffect, useState } from 'react'
import imgurlEasy from "../image/IndustrialManufacturing_img.svg";
import imgurlsource from "../image/IndustrialManufacturing_img.svg";
import imgurlWorkflow from "../image/IndustrialManufacturing_img.svg";
import imgurlEasyPD from "../image/Cosmetics_img.svg";
import imgurlsourcePD from "../image/Cosmetics_img.svg";
import imgurlWorkflowPD from "../image/Cosmetics_img.svg";
import imgurlWorkflowM from "../image/IndustrialManufacturing_img.svg";
import img2 from "../image/IndustrialManufacturing.svg";
import img3 from "../image/Cosmetics.svg";
import img_url from "../image/Industrial_Manufacturing_img.svg"
import imgbg from "../image/Industrial_Manufacturing_img.svg"
import Page_Discretelndustries1 from "../Page_DiscreteIndustries1";
import Page_Discretelndustries2 from "../Page_DiscreteIndustries2";
import Page_Discretelndustries4 from "../Page_Discretelndustries4"
import Industrial from "../../Component/image/mobileImages/IndustrialManufu.svg"
import Cosmetics from "../../Component/image/mobileImages/Cosmetics.svg"



const Plm_fourtwo_Main_file = () => {

  const [screenWidth, setScreenWitdh] = useState(true)
  useEffect(() => {
    if (window.screen.width < 430) { setScreenWitdh(!screenWidth) }
  }, [])

  return (
    <div>
      {
        screenWidth ? <div>
          <Page_Discretelndustries1
            title={
              <>
                <span className="boldSpan">D</span>iscrete{" "}
                <span className="boldSpan"> I</span>ndustries
              </>
            }
            body={
              "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
            }

          />

          <Page_Discretelndustries2
            title={
              <>

                <span className="boldSpan">I</span>ndustrial  {"  "}
                &nbsp;  <span className="boldSpan">M</span>anufacturing

              </>

            }
            imgurlone={imgurlEasy}
            imgtext1={"ActionPLM helps the industry to enhance the speed and quality of new product launches and engineering modification orders."}
            imgurltwo={imgurlsource}
            imgtext2={"Manufacturers are transforming their ways in which they design, manufacture, and maintain the products that they sell to customers."}
            imgurlthree={imgurlWorkflow}
            imgtext3={"Every organisation made an effort to stay ahead of the competition by incorporating new technology that met their requirements."}
            imgurlfour={imgurlWorkflowM}
            imgtext4={"Many successful businesses do this by using digital technologies and changing to a customer-centric business strategy."}

            imgurl_img={img_url}
            imgurl={img2}
            colorname="purpal"
            classpara="img_icon"
            idpara="icon_img"

            automotivebg_img="automotive_img"
            Automotive_img="Manufacturing"
          />
        </div> :
          <div>
            <div>
              <Page_Discretelndustries1
                title={
                  <>
                    <span className="boldSpan">D</span>iscrete{" "}
                    <span className="boldSpan"> I</span>ndustries
                  </>
                }
                body={
                  "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                }

              />
            </div>
            <div>
              <img src={Industrial} alt="alt" width="100%" height="100%" />
            </div>
          </div>
      }

      {
        screenWidth ? <div>
          <Page_Discretelndustries1
            title={
              <>
                <span className="boldSpan">P</span>rocess{" "}
                <span className="boldSpan">I</span>ndustries
              </>
            }
            body={
              "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
            }
          />
          <Page_Discretelndustries4
            imgurl1={img3}

            title={
              <>
                <span className="boldSpan">C</span>osmetics{" "}

              </>

            }

            imgurlonepD={imgurlEasyPD}
            imgtext1={"The cosmetics sector is very competitive due to shelf positioning and brand familiarity, which have a considerable impact on consumers' rapid selections. "}
            imgurltwopD={imgurlsourcePD}
            imgtext2={"Manufacturers face a problem as consumers demand for environment friendly product packaging, material handling and regulatory ingredient management rise."}
            imgurlthreepD={imgurlWorkflowPD}
            imgtext3={"ActionPLM helps the cosmetics sector with operations and New Product Development initiatives (N.P.D.). It also manages product quality, traceability and regulatory complexity in a global environment without compromising product release dates."}

            automotivebg_img="Industrial_Manufacturing"
            colortitle="pink"

          />
        </div> :
          <div>
            <div>
              <Page_Discretelndustries1
                title={
                  <>
                    <span className="boldSpan">P</span>rocess{" "}
                    <span className="boldSpan">I</span>ndustries
                  </>
                }
                body={
                  "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                }
              />
            </div>
            <div>
              <img src={Cosmetics} alt="img" width="100%" height="100%" />
            </div>
          </div>
      }

    </div>
  )
}
export default Plm_fourtwo_Main_file;