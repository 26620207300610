import React from "react";
import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
import img1 from "../image/bomConfig.svg";

import box from "../image/1.svg";
import Req_two from "../image/2.svg";
import Req_three from "../image/3.svg";

import Group_one from "../image/ManageProductVariants.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/ImproveEfficiency.svg";
import Group_bell from "../image/ReduceErrors.svg";
function plm_three_Main_file() {
  const svgFrt = (
    <svg
      className="svgTen"
      viewBox="0 0 1024 1198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path10"
        fill="none"
        stroke="#5C90C4"
        stroke-width="4"
        stroke-linejoin="round"
        d="M430.479 90.7082C327.479 -139.793 -201.645 125.198 87.3554 359.198C141.737 403.23 316.375 434.969 657.356 240.699C1000.48 45.2086 1103.98 283.198 955.478 336.709C801.525 392.184 357.5 430.492 357.5 637.5C357.5 778 753.359 587.701 749 722.5C900.479 944 8 1336 900.479 1143.5"
      />

      <path
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        stroke-width="4"
        className="dashed1"
        d="M430.479 90.7082C327.479 -139.793 -201.645 125.198 87.3554 359.198C141.737 403.23 316.375 434.969 657.356 240.699C1000.48 45.2086 1103.98 283.198 955.478 336.709C801.525 392.184 357.5 430.492 357.5 637.5C357.5 778 753.359 587.701 749 722.5C900.479 944 8 1336 900.479 1143.5"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page10_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page10_p2").style.display = "block";
  }, 2200);

  setTimeout(() => {
    document.querySelector(".Page10_p3").style.display = "block";
  }, 3200);
  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">B</span>OM{" "}
            <span className="boldSpan">C</span>onfiguration
          </>
        }
        body={
          `BOM Configuration ensures product specification and manufacturing execution consistency. 
          It provides advanced capabilities like defining, navigating and filtering  along with updated configured BOMs based on product quantity, features and effective date. 
          Action PLM improves the user experience of product development by providing insight into the functional linkage of product's specification and by verifying design and BOM information simultaneously.`
        }
        imgurl={img1}
        changemanagmentimg="bomConfigurationMobile"
        changemanagmentimg1="changemanagment_img"
        
        

      />
      <Page2
        classes={["Page10_p1", "Page10_p2", "Page10_p3"]}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
        svgFrt={svgFrt}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Manage Product Variants"}
        bottom_two_P={"Improve Efficiency"}
        bottom_three_P={"Reduce Errors"}
        fved="group_bell_img"
        id="bomConfi"
      
      />
    </div>
  );
}

export default plm_three_Main_file;
