import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import "./Page_partplmthree.css";
import { Pagination, Navigation } from "swiper";

const Page_partplmthree = ({
  title,
  body,
  imgurl,
  imgurlone,
  imgurltwo,
  titleh1,
  imgurlCRM,
  imgurlERP,
  imgurlCAD,
  imgurlOTHER,
  classtitle,
  classicon,
  CRM_IMG,
  id_box,
  box,
  IMG_CRM,
  CAD_IMG,
}) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  let appendNumber = 4;
  let prependNumber = 1;

  const prepend2 = () => {
    swiperRef.prependSlide([
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
    ]);
  };

  const prepend = () => {
    swiperRef.prependSlide(
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>"
    );
  };

  const append = () => {
    swiperRef.appendSlide(
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>"
    );
  };

  const append2 = () => {
    swiperRef.appendSlide([
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
    ]);
  };

  return (
    <div>
      <div id={box} className="contentdiv">
        <div className="sectionplmthree">
          <div>
            <h1 className={classtitle}> {title}</h1>
          </div>

          <div className="contentimgss">
            <div className="contentmg">
              <div className="imgIntegration1">
                <img className={classicon} src={imgurlone} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurltwo} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurlone} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurltwo} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurlone} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="contentimgOTHERS1">
          <div className="contentmg1">
            <div className="imgIntegration2">
              <img src={imgurlCRM} alt="" />
            </div>

            <div className="imgIntegration2">
              <img id={CRM_IMG} src={imgurlERP} alt="" />
            </div>

            <div className="imgIntegration2">
              <img src={imgurlCAD} alt="" />
            </div>

            <div className="imgIntegration2">
              <img src={imgurlOTHER} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id={id_box} className="contentdiv">
        <div className="sectionplmthree">
          <div>
            <h1 className={classtitle}> {title}</h1>
          </div>
          <div className="contentimgss">
            <div className="contentmg">
              <div className="imgIntegration1">
                <img className={classicon} src={imgurlone} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurltwo} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurlone} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurltwo} alt="" />
              </div>
              <div className="imgIntegration1">
                {" "}
                <img src={imgurlone} alt="" />
              </div>

              <div className="contentimgOTHERS1">
                <div className="contentmg1">
                  <Swiper
                    onSwiper={setSwiperRef}
                    slidesPerView={3}
                    centeredSlides={true}
                    spaceBetween={100}
                  
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="imgIntegration2">
                        <img src={imgurlCRM} alt="" />
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="imgIntegration2">
                        <img src={imgurlERP} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="imgIntegration2">
                        <img src={imgurlCAD} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="imgIntegration2">
                        <img src={imgurlOTHER} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page_partplmthree;
