import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./navBar.css"
import icon from "../image/logo.svg"

import partIcon from "../image/DDIcon/Part.svg"
import RequirementMgt from "../image/DDIcon/RequirementMgt.svg"
import ChangeMgt from "../image/DDIcon/ChangeMgt.svg"
import InventoryMgt from "../image/DDIcon/InventoryMgt.svg"
import QualityMgt from "../image/DDIcon/QualityMgt.svg"
import SupplierMgt from "../image/DDIcon/SupplierMgt.svg"
import FileMgt from "../image/DDIcon/FileMgt.svg"
import Bom from "../image/DDIcon/BOM.svg"
import MBOM from "../image/DDIcon/MBOM.svg"
import FormulationMgt from "../image/DDIcon/FormulationMgt.svg"
import ArtworkMgt from "../image/DDIcon/ArtworkMgt.svg"
import IntegrationMgt from "../image/DDIcon/IntegrationMgt.svg"
import Workflow from "../image/DDIcon/WorkflowMgt.svg"
import PSB from "../image/PSB.svg";
import rich from "../image/rich.svg"
import IngredientManagement_Icon from "../image/DDIcon/IngredientManagement_Icon.svg"
import TestTrials_icon from "../image/DDIcon/TestTrials_icon.svg"
import BomConfiguration from "../image/DDIcon/BomConfiguration.svg"


const DropDown = ({ onClick }) => {
    var url = window.location.pathname;
    var page_ID = url.substring(url.lastIndexOf('/') + 1);
    const [page, setpage] = useState(page_ID)

    return (
        <div className="DropDown">
            <div className='DropDownCard'>
                <div className='content_items'>
                    <div className='content_item'>
                        <div className='content-heading'>Discrete PLM</div>
                        <div className='list_drop'>
                            <div className={`itemContent ${page === "Requirement-Management" ? 'activehover' : ''} `}>
                                <div className='iconCricle'>
                                    <img src={RequirementMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="/Requirement-Management" onClick={onClick}>Requirement Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Part-Master" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={partIcon} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="/Part-Master" onClick={onClick}>Part Master</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Change-management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={ChangeMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Change-management" onClick={onClick}>Change Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Bill-Material" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={Bom} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Bill-Material" onClick={onClick}>Bill of Material</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "File-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={FileMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="File-Management" onClick={onClick}>File Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Supplier-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={SupplierMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Supplier-Management" onClick={onClick}>Supplier Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Quality-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={QualityMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Quality-Management" onClick={onClick}>Quality Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Inventory-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={InventoryMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Inventory-Management" onClick={onClick}>Inventory Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "MBOM" ? 'activehover' : ''}`} style={{ width: "350px" }}>
                                <div className='iconCricle'>
                                    <img src={MBOM} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="MBOM" onClick={onClick}>Manufacturing Bill of Material (MBOM)</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Bom-Configuration" ? 'activehover' : ''}`} style={{ width: "350px" }}>
                                <div className='iconCricle' id="BOMConfiguration">
                                    <img src={BomConfiguration} alt='icon'/>
                                </div>
                                <div className='item-title'>
                                    <Link to="Bom-Configuration" onClick={onClick}>BOM Configuration</Link>
                                </div>
                            </div>
                        </div>
                        <div className='content-heading mt-4'>Process PLM</div>
                        <div className='list_drop'>
                            <div className={`itemContent ${page === "Ingredient-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={IngredientManagement_Icon} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Ingredient-Management" onClick={onClick}>Ingredient Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Formulation-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={FormulationMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Formulation-Management" onClick={onClick}>Formulation Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Test-Trials" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={TestTrials_icon} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Test-Trials" onClick={onClick}>Test & Trials</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Artwork-Labelling" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={ArtworkMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Artwork-Labelling" onClick={onClick}>Artwork & Labelling</Link>
                                </div>
                            </div>
                        </div>
                        <div className='content-heading mt-4'>Others</div>
                        <div className='list_drop'>
                            <div className={`itemContent ${page === "Integration-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={IntegrationMgt} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Integration-Management" onClick={onClick}>Integration Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Workflow-Management" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={Workflow} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="Workflow-Management" onClick={onClick}>Workflow Management</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Rich-Analytics" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={rich} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="/Rich-Analytics" onClick={onClick}>Rich Analytics</Link>
                                </div>
                            </div>
                            <div className={`itemContent ${page === "Permission-based-access" ? 'activehover' : ''}`}>
                                <div className='iconCricle'>
                                    <img src={PSB} alt='icon' />
                                </div>
                                <div className='item-title'>
                                    <Link to="/Permission-based-access" onClick={onClick}>Permission Based Access</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DropDown