import React from "react";
import "./Page_DiscreteIndustries4.css";
function Page_Discretelndustries4({
  title,
  body,
  imgurl1,
  imgurlonepD,
  imgurltwopD,
  imgurlthreepD,
  imgtext1,
  imgtext2,
  imgtext3,
  imgurlfourpD,
  imgtext4,
  colortitle,
  idName,
}) {
  return (
    <div className="section4" id={idName}>
      <div className="image-section4">
        <img src={imgurl1} alt="" />
      </div>
      <div className="content-section4">
        <h1 className={colortitle}> {title}</h1>

        <p>{body}</p>
        <div className="content-sectionplmtwoDi14">
          <div className="contentimgsDi14">
            <div className="imgIntegrationDi14">
              <img src={imgurlonepD} alt="" />
              <p>{imgtext1}</p>
            </div>
            <div className="imgIntegrationDi14">
              {" "}
              <img src={imgurltwopD} alt="" />
              <p>{imgtext2}</p>{" "}
            </div>
            <div className="imgIntegrationDi14">
              {" "}
              <img src={imgurlthreepD} alt="" />
              <p> {imgtext3}</p>
            </div>
            <div className="imgIntegrationDi14">
              {" "}
              <img src={imgurlfourpD} alt="" />
              <p> {imgtext4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page_Discretelndustries4;
