import React from "react";
import img1 from "../image/Manufacturin_Bill_img.svg";


import box from "../image/Manufacturin_Bill_cardone.svg";
import Req_two from "../image/Manufacturin_Bill_cardtwo.svg";


import Group_one from "../image/Manufacturin_Bill_Development.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/Manufacturin_Bill_process.svg";
import Group_bell from "../image/Manufacturin_Bill_clock.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_nine_Main_file() {
  const svgFrt = (
    <svg
    className="svgnine"
    viewBox="0 0 999 824"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    className="path" fill="none"
    stroke="#5C90C4"
    stroke-width="4"
    stroke-linejoin="round"
    stroke-miterlimit="40"
      d="M397.956 101.034C294.956 -129.468 -181.073 84.3512 78.4562 315.243C150.957 379.743 407.956 372.534 634.834 219.233C962.044 -1.86227 1081.46 261.732 932.956 315.243C779.003 370.718 664.881 322.508 397.956 503.743C86.4564 715.243 492.5 949.034 949 739.534"
     
    />
    <path
    path fill="none"
  stroke="white"
  stroke-linejoin="round"
  stroke-miterlimit="40" 
  stroke-width="4"
  className="dashed"
      d="M397.956 101.034C294.956 -129.468 -181.073 84.3512 78.4562 315.243C150.957 379.743 407.956 372.534 634.834 219.233C962.044 -1.86227 1081.46 261.732 932.956 315.243C779.003 370.718 664.881 322.508 397.956 503.743C86.4564 715.243 492.5 949.034 949 739.534"
      
    />
  </svg>
    );
  setTimeout(() => {
    document.querySelector(".Page9_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page9_p2").style.display = "block";
  }, 2600);
return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">M</span>anufacturing
            <span className="boldSpan"> B</span>ill
            <span className="boldSpan"> O</span>f
            <span className="boldSpan"> M</span>aterial
            <span className="boldSpan"> (MBOM)</span>
          </>
        }
        body={
          "ActionPLM makes it simple to create MBOM from BOM. MBOM is focused on the parts that are needed to manufacture a product. In addition, the part list in the MBOM contains all the information about how the parts are related to each other and contains other details required to build and finish a product for customers."
        }
        imgurl={img1}
        changemanagmentimg="imgchangemag2"
        changemanagmentimg1="changemanagment_img"

      />

      <Page2
        classes={["Page9_p1", "Page9_p2"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
    
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Accelerated Product Development"}
        bottom_two_P={"Accurate Execution Of Process"}
        bottom_three_P={"Reduce Production Mistakes"}
      />
    </div>
  );
}

export default plm_nine_Main_file;
