import React from "react";
import img1 from "../image/Quality_Management_img.svg";

import box from "../image/Quality_Management_img_onecard.svg";
import Req_two from "../image/Quality_Management_cardimg.svg";

import Group_one from "../image/change_managment_multi-Level-img.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/resolve.svg";
import Group_bell from "../image/control.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_seven_Main_file() {
  const svgFrt = (
    <svg
      className="svgseven"
      viewBox="0 0 1028 799"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        fill="none"
        stroke="#5C90C4"
        stroke-width="4"
        stroke-linejoin="round"
        d="M397.956 101.499C294.956 -129.002 -181.073 84.817 78.4562 315.708C150.957 380.209 407.956 373 634.834 219.699C962.044 -1.39645 1081.46 262.198 932.956 315.708C779.003 371.184 664.881 322.974 397.956 504.209C86.4564 715.709 570 918 1026.5 708.5"
      />
      <path
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        stroke-width="4"
        className="dashed"
        d="M397.956 101.499C294.956 -129.002 -181.073 84.817 78.4562 315.708C150.957 380.209 407.956 373 634.834 219.699C962.044 -1.39645 1081.46 262.198 932.956 315.708C779.003 371.184 664.881 322.974 397.956 504.209C86.4564 715.709 570 918 1026.5 708.5"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page7_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page7_p2").style.display = "block";
  }, 2700);

  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">Q</span>uality{" "}
            <span className="boldSpan">M</span>anagement{" "}
          </>
        }
        body={
          "Quality management is an essential process of every industry. Quality begins with product design, development and extends through incoming parts, delivery, and servicing. ActionPLM manages all quality management data across all departments and can also track the progress of any quality issue and simply accept or reject the solution of that particular issue by using the multi-level approval process."
        }
        imgurl={img1}
        changemanagmentimg1="changemanagment_img"
        changemanagmentimg="change_managment_img"
      />
      <Page2
        classes={["Page7_p1", "Page7_p2"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Multi-Level Approval Process"}
        bottom_two_P={"Resolve Issues Faster"}
        bottom_three_P={"Revision Control"}
        fved1="group_img"
      />
    </div>
  );
}

export default plm_seven_Main_file;
