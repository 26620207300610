import React, { useState, useEffect } from "react";
import imgurlEasy from "../image/ElectricVehicles1.svg";
import imgurlsource from "../image/ElectricVehicles1.svg";
import imgurlWorkflow from "../image/ElectricVehicles1.svg";
import imgurlEasyPD from "../image/Textile_img.svg";
import imgurlsourcePD from "../image/Textile_img.svg";
import imgurlWorkflowPD from "../image/Textile_img.svg";
import imgurlWork1flowPD from "../image/Textile_img.svg";
import imgurlWorkflowM from "../image/IndustrialManufacturing_img.svg";
import img2 from "../image/Electric-Vehicles_img.svg";
import img3 from "../image/Textile.svg";
import Page_Discretelndustries1 from "../Page_DiscreteIndustries1";
import Page_Discretelndustries2 from "../Page_DiscreteIndustries2";
import Page_Discretelndustries4 from "../Page_Discretelndustries4";
import ElectricVehicles from "../../Component/image/mobileImages/ElectricVehicles.svg"

const Plm_fourone_Main_file = () => {
    const [screenWidth, setScreenWitdh] = useState(true)
    useEffect(() => {
        if (window.screen.width < 430) { setScreenWitdh(!screenWidth) }
    }, [])

    return (
        <div>
            <div>
                <Page_Discretelndustries1
                    title={
                        <>
                            <span className="boldSpan">P</span>rocess{" "}
                            <span className="boldSpan">I</span>ndustries
                        </>
                    }
                    body={
                        "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                    }
                />

                <Page_Discretelndustries4
                    imgurl1={img3}
                    title={
                        <>
                            <span className="boldSpan">T</span>extile{" "}
                        </>
                    }
                    imgurlonepD={imgurlEasyPD}
                    imgtext1={
                        "The fashion and apparel industry is competitive and dynamic, so speed to market is critical. To keep you one step ahead of the competition, you need a solution that can provide visibility throughout the supply chain from beginning to end. "
                    }
                    imgurltwopD={imgurlsourcePD}
                    imgtext2={
                        "In order to compete in this dynamic and highly competitive industry retailers, manufacturers and brands must develop more quickly and provide products at competitive prices and build brand equity. "
                    }
                    imgurlthreepD={imgurlWorkflowPD}
                    imgtext3={
                        "Product life cycle management (PLM) in the textile sector entails controlling the entire process from conception to completion."
                    }
                    imgurlfourpD={imgurlWork1flowPD}
                    imgtext4={
                        "ActionPLM facilitates the textile industry's operations management and associations of its workforce on a single platform."
                    }
                    colortitle="darkblue"
                />
            </div>
            <div>
                {
                    screenWidth ? <div>
                        <Page_Discretelndustries1
                            title={
                                <>
                                    <span className="boldSpan">D</span>iscrete{" "}
                                    <span className="boldSpan"> I</span>ndustries
                                </>
                            }
                            body={
                                "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                            }
                        />

                        <Page_Discretelndustries2
                            title={
                                <>
                                    <span className="boldSpan">E</span>lectric  {" "}
                                    <span className="boldSpan">V</span>ehicles
                                </>
                            }
                            imgurlone={imgurlEasy}
                            imgtext1={
                                "Electric vehicles are the constant revolution in the automotive industry and they are one of the key sectors of innovation today."
                            }
                            imgurltwo={imgurlsource}
                            imgtext2={
                                "ActionPLM adds significant value to this industry by improving mapping, streamlining the product design process, and providing value to businesses."
                            }
                            imgurlthree={imgurlWorkflow}
                            imgtext3={
                                "It enables the EV sector to benefit from current industry advancements, manage partnerships and suppliers, decrease product development timelines and bring new ideas to market quickly and efficiently with plentiful resources."
                            }
                            imgurl={img2}
                            colorname="green"
                            idpara="idparas"
                            iD_name="id_names"
                            classpara="classpara_icon"

                        />
                    </div> :
                        <div>
                            <div>
                                <Page_Discretelndustries1
                                    title={
                                        <>
                                            <span className="boldSpan">D</span>iscrete{" "}
                                            <span className="boldSpan"> I</span>ndustries
                                        </>
                                    }
                                    body={
                                        "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                                    }
                                />
                            </div>
                            <div>
                                <img src={ElectricVehicles} alt="img" width="100%" height="100%" />
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}
export default Plm_fourone_Main_file;
