import React from "react";

import img1 from "../image/Group_vector_img.svg";

import box from "../image/PLM_ONE_iMG.svg";
import Req_two from "../image/PLM_TWO_iMG.svg";
import Req_three from "../image/PLM_THREE_iMG.svg";

import Group_one from "../image/plm_Navigation_image.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/plm_Revision_img.svg";
import Group_bell from "../image/plm_Detailed_img.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";

function plm_two_Main_file() {
  const svgFrt = (
    <svg
      viewBox="0 0 1006 789"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svgpath"
    >
      <path
        fill="none"
        stroke="#5C90C4"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M271.877 111.99C168.877 -118.511 -173.623 49.4896 115.377 283.49C169.759 327.522 359.815 384.12 685.378 164.991C1049.38 -80.0098 1053.88 248.992 930.378 274.491C834.106 294.369 417.877 223.492 469.377 541.992C501.474 740.492 581 876 770.5 716.5"
        stroke-width="4"
        className="path"
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M271.877 111.99C168.877 -118.511 -173.623 49.4896 115.377 283.49C169.759 327.522 359.815 384.12 685.378 164.991C1049.38 -80.0098 1053.88 248.992 930.378 274.491C834.106 294.369 417.877 223.492 469.377 541.992C501.474 740.492 581 876 770.5 716.5"
        stroke-width="4"
      />
    </svg>
  );

  setTimeout(() => {
    document.querySelector(".Page2_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page2_p2").style.display = "block";
  }, 2000);

  setTimeout(() => {
    document.querySelector(".Page2_p3").style.display = "block";
  }, 2700);

  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">P</span>art{" "}
            <span className="boldSpan">M</span>aster
          </>
        }
        body={
          "In every manufacturing industry a robust part master information is required to handle hundreds of parts each day. With ActionPLM’s part master module, you can manage all parts and their specific details in one single place."
        }
        imgurl={img1}
        changemanagmentimg1="changemanagment_img"
        changemanagmentimg="change_managment_img"
      />

      <Page2
        classes={["Page2_p1", "Page2_p2", "Page2_p3"]}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
        svgFrt={svgFrt}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Easy Navigation"}
        bottom_two_P={"Effective Revision Handling"}
        bottom_three_P={"Detailed Part Master"}
        fved_group="groupE_img"
        
      />
    </div>
  );
}

export default plm_two_Main_file;
