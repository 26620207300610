/*import React from "react";
import Group_one from "./image/Group_one.svg";
import Vector_one from "./image/Vector_one.svg";
import Group_two from "./image/Group_two.svg";
import Group_bell from "./image/Group_bell.svg";
import "./Pagethree.css";
function Pagethree() {
  return (
    <div className="Product_Group">
      <div className="Group_crcle">
        <img className="Group_one" src={Group_one} />
        <img className="Vector_one" src={Vector_one} />
        <p>
          Product Requirement  Tracking
        </p>
      </div>

      <div className="Group_w">
        <img className="Group_two" src={Group_two} />
        <img className="Vector_one" src={Vector_one} />
        <p>
          Pre - Planning Of <br /> Budgets
        </p>
      </div>

      <div className="Group_bell">
        <img className="Group_one" src={Group_bell} />
        <img className="Vector_one" src={Vector_one} />
        <p>
          Automated Notification System
        </p>
      </div>
    </div>
  );
}

export default Pagethree;*/

/*import React from "react";
//4import img1 from  "./image/Group_Pc.svg";

/*import "./Page_one.css";
function Page_one({title, body,imgurl}) {
  return (
    <div className="section">
      <div className="content-section">
        <h1> {title}
        </h1>

        <p>
         {body}
        </p>
      </div>*/
import "./Pagethree.css";
function Pagethree({
  imgurl_plm_one,

  imgurl_plm_two,
  imgurl_plm_three,

  imgurl_plm_five,
  bottom_one_P,
  bottom_two_P,
  bottom_three_P,
  fved,
  fved1,
  fved_group,
  id
}) {
  return (
    <div className="Product_Group" id={id}>
      <div className="Group_crcle" id={fved1}>
        <img src={imgurl_plm_one} alt="" />
        <img className="Vector_one" src={imgurl_plm_two} alt="" />
        <p>{bottom_one_P} </p>
      </div>
      <div className="Group_w " id={fved_group}>
        <img src={imgurl_plm_three} alt="" />
        <img className="Vector_one" src={imgurl_plm_two} alt="" />
        <p>{bottom_two_P}</p>
      </div>

      <div className="Group_bell" id={fved}>
        <img src={imgurl_plm_five} alt="" />
        <img className="Vector_one " src={imgurl_plm_two} alt="" />
        <p>{bottom_three_P}</p>
      </div>
    </div>
  );
}

export default Pagethree;
