import React, { useState, useRef } from 'react'
import { Button, Container } from 'react-bootstrap';
import "./solutions.css";
import sliderIcon from "../../image/sliderIcon.svg";
import Linedraw from "../../image/line-draw.png";
import { BiDownArrow } from "react-icons/bi"
import ArrowDown from "../../image/DDIcon/arrowDown.svg"
import Solutions from "../../image/solution.svg"
import mobileSolution from "../../image/solution.svg"
import mobileSVGLine from "../../image/mobileSvgLine.svg"
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Autoplay } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import Mobileslider from './Mobileslider';



const Solution = () => {
    let btnRef = useRef();
    const [replace, SetReplaceArrow] = useState(false)
    const [replaceLeft, SetReplaceArrowLeft] = useState(false)
    var slide = document.getElementById("slide");
    var slide_mobile = document.getElementById("slide_mobile");
    var slideRight_mobile = document.getElementById("slideRight_mobile")
    var slideRight = document.getElementById("slideRight")

    let x = 0;
    let y = 0;
    const slideBox = () => {
        if (x > "-600") {
            x = x - 90;
            slide.style.top = x + "px";
            slide_mobile.style.top = x + "px"
            console.log(x)
        }
    }
    const slidedown = () => {
        if (x < 0) {
            x = x + 90;
            slide.style.top = x + "px";
            slide_mobile.style.top = x + "px";
        }
    }

    const slideRightTop = () => {
        if (y > "-10") {
            console.log(y)
            y = y - 90;
            slideRight.style.top = y + "px";
            slideRight_mobile.style.top = y + "px";
        }
    }
    const slideRightDown = () => {
        if (y < 0) {
            y = y + 90;
            slideRight.style.top = y + "px";
            slideRight_mobile.style.top = y + "px";
        }
    }



    return (
        <div className='sliderDataTop'>
            <Container className='sliderCards'>
                <div className='DiscreteIndustry'>
                    <p style={{ borderBottom: "3px solid #5C90C5" }}>Discrete Industry</p>
                    <div className='sliderData'>
                        <div className='slideImg'>
                            <div className='slideImgflex'>
                                <Button ref={btnRef} style={{ background: "none", border: "white" }} id="up" onClick={slidedown}>
                                    <div className='arrow-down'>
                                        <img src={sliderIcon} className="up-down" />
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div className='MainSlider'>
                            <div className='sliderInner' id='slide'>
                                <Link to="/Requirement-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p>Requirement Management</p>
                                    </div>
                                </Link>
                                <Link to="/Part-Master">
                                    <div className='innerSliderBox border-gradient '>
                                        <p className='PartM'>Part Master</p>
                                    </div>
                                </Link>
                                <Link to="/Change-management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>Change Management</p>
                                    </div>
                                </Link>
                                <Link to="/MBOM">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='MBom'>Manufacturing Bill of Material (MBOM)</p>
                                    </div>
                                </Link>
                                <Link to="/Bill-Material">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>Bill of Material</p>
                                    </div>
                                </Link>
                                <Link to="Quality-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>Quality Management</p>
                                    </div>
                                </Link>
                                <Link to="BOM-Configuration">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>BOM Configuration</p>
                                    </div>
                                </Link>
                                <Link to="Inventory-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p>Inventory Management</p>
                                    </div>
                                </Link>
                                <Link to="File-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>File Management</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='slideImg'>
                            <div className='slideImgflex'>
                                <Button style={{ background: "none", border: "white" }} id="Down" onClick={slideBox}>
                                    <div className='arrow-up'>
                                        <img src={sliderIcon} alt="ArrowDown" className="up" />
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sliderHome'>
                    <img src={Linedraw} alt='Linedraw' className="data_" />
                    <img src={mobileSVGLine} width="100%" className='data_mobile' />
                    <div className='CricleImg_industry'>
                        <img src={Solutions} width="100%" />
                        {/* <div className='CricleImg_industry_inner'></div>
                        <p>Solutions</p> */}
                    </div>
                </div>
                <div className='ProcessIndustry'>
                    <p style={{ borderBottom: "3px solid #5C90C5" }}>Process Industry</p>
                    <div className='sliderData'>
                        <div className='slideImg'>
                            {/* <img src={sliderIcon} /> */}
                            <div className='slideImgflex'>
                                <Button style={{ background: "none", border: "white" }} id="upRight" onClick={slideRightDown}>
                                    <div className='arrow-down'>
                                        <img src={sliderIcon} alt="ArrowDown" className="up-down" />
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div className='MainSlider'>
                            <div className='sliderInner' id='slideRight'>
                                <Link to="/Ingredient-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p>
                                            Ingredient Management
                                        </p>
                                    </div>
                                </Link>
                                <Link to="/Formulation-Management">
                                    <div className='innerSliderBox border-gradient '>
                                        <p>Formulation Management</p>
                                    </div>
                                </Link>
                                <Link to="/Test-Trials">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartMT'>Test & Trials</p>
                                    </div>
                                </Link>
                                <Link to="/Artwork-Labelling">
                                    <div className='innerSliderBox border-gradient'>
                                        <p className='PartM'>Artwork & Labelling</p>
                                    </div>
                                </Link>
                                {/* <Link to="/Workflow-Management">
                                    <div className='innerSliderBox border-gradient'>
                                        <p>Workflow Management</p>
                                    </div>
                                </Link> */}
                            </div>
                        </div>
                        <div className='slideImg'>
                            <div className='slideImgflex'>
                                <Button style={{ background: "none", border: "white" }} id="darkArrow" onClick={slideRightTop}>
                                    <div className='arrow-up'>
                                        <img src={sliderIcon} alt="ArrowDown" className="up" />
                                    </div>
                                </Button>
                            </div>
                            {/* <img src={replace ? sliderIcon : ArrowDown} id="darkArrow" /> */}
                        </div>
                    </div>
                </div>
            </Container>
            <Container className='sliderCard_mobile'>
                <div className='sliderHome'>
                    <img src={Linedraw} alt='Linedraw' className="data_" />
                    <img src={mobileSVGLine} width="100%" className='data_mobile' />
                    <div className='CricleImg_industry'>
                        <img src={Solutions} width="100%" />
                    </div>
                </div>
                <Mobileslider
                    slideBox={() => slideBox()}
                    slidedown={() => slidedown()}
                    slideRightTop={() => slideRightTop()}
                    slideRightDown={() => slideRightDown()}
                />
            </Container>
        </div>
    )
}

export default Solution