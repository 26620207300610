import React from 'react'
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Autoplay } from "swiper";
import "./mobileSwiper.css"
import "swiper/css/pagination";
import "swiper/css";

const MobileSwiper = () => {
    const sliderCards = [
        {
            text: "Automotive",
            link: "Automotive"
        },
        {
            text: "Industrial Manufacturing",
            link: "Industrial-Manufacturing"
        },
        {
            text: "Aerospace & Defence",
            link: "Aerospace-Defence"
        },
        {
            text: "Electric Vehicles",
            link: "Electric-Vehicles"
        },
        {
            text: "Food and Beverages",
            link: "Food-beverages"
        },
        {
            text: "Cosmetics",
            link: "Cosmetics"
        },
        {
            text: "Pharmaceuticals",
            link: "Pharmaceuticals"
        },
        {
            text: "Textile",
            link: "Textile"
        },

    ]


    return (
        <div className='mobileSlider'>
            <Container className='Industries'>
                <h2 className='para_bulid'><span>Industries</span></h2>
                <p className='subbulid_text'>With ActionPLM, create a digital twin of your products and business.</p>
            </Container>
            <div className='mobileSwiper' style={{ marginTop: '5rem' }}>
                <div className='discrete'>
                    <p>Discrete</p>
                </div>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={270}
                    loop
                    pagination={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 270
                        }
                    }}
                    autoplay={true}
                    modules={[Mousewheel, Autoplay, Pagination]}
                    className="mySwiper">
                    {
                        sliderCards.slice(0, 4).map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Link to={`/${item.link}`} style={{ height: "500px" }}>
                                        <div className='contentflex rotateImg d-flex justify-content-between' style={{ width: "320px", height: "70px", marginTop: "2rem" }}>
                                            <div className='toppadding rotatetext'>{item.text}</div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className='mt-3 mb-5'>
                <div className='discrete'>
                    <p>Process</p>
                </div>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={270}
                    loop
                    pagination={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 270
                        }
                    }}
                    autoplay={
                        {
                            delay: 2500,
                            reverseDirection: true,
                        }
                    }
                    modules={[Mousewheel, Autoplay, Pagination]}
                    className="mySwiper">
                    {
                        sliderCards.slice(4, 8).map((item, index) => {
                            return (
                                <SwiperSlide key={index} className="ProcessIndustry">
                                    <Link to={`/${item.link}`} style={{ height: "500px" }}>
                                        <div className='contentflex  d-flex justify-content-between' style={{ width: "320px", height: "70px", marginTop: "2rem" }}>
                                            <div className='toppadding rotatetext'>{item.text}</div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div >
    )
}

export default MobileSwiper