/*import React from "react";
import "./Page_one.css";
function page_one() {
  return (
    <div class="section">
        <div class="content-section">
            <h1>
            <span className="boldSpan">R</span>equirement <span className="boldSpan">M</span>anagement
            </h1>
            <p>
              Industries are facing lots of complexity to manage their process
              because of legacy systems. With ActionPLM, you can manage all your
              requirements along with tracking and approval processes.
            </p>
        </div>

        <div class="image-section">
          <img class="Group_Pc" src={Group_Pc} />
        </div>
    </div>
  );
}
export default page_one;*/
import React from "react";
//4import img1 from  "./image/Group_Pc.svg";

import "./Page_one.css";
function Page_one({ title, body, imgurl, changemanagmentimg, titleplmone,imghidden1,changemanagmentimg1}) {
  return (
    <div className="section">
      <div className="content-section">
      

        <h1> {title}</h1>
        <div id={changemanagmentimg1} className="image-section">
        <img src={imgurl} alt="" />
      </div>

        <p>{body}</p>
      </div>

      <div id={changemanagmentimg} className="image-section">
        <img src={imgurl} alt="" />
      </div>
    </div>
  );
}

export default Page_one;
