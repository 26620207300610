import React, { useEffect, useState } from "react";
import imgurlEasy from "../image/SteeringWheel.svg";
import imgurlsource from "../image/SteeringWheel.svg";
import imgurlWorkflow from "../image/SteeringWheel.svg";
import imgurlEasyPD from "../image/Food&BeveragePi.svg";
import imgurlsourcePD from "../image/Food&BeveragePi.svg";
import imgurlWorkflowPD from "../image/Food&BeveragePi.svg";
import img1 from "../image/automotive_bg.svg";
import img2 from "../image/automotive.svg";
import img3 from "../image/Food&Beverage.svg";
import imgslider from "../image/Arrow.svg";
import Page_Discretelndustries1 from "../Page_DiscreteIndustries1";
import Page_Discretelndustries2 from "../Page_DiscreteIndustries2";
import Page_Discretelndustries4 from "../Page_Discretelndustries4";
import FoodBeverage from "../../Component/image/mobileImages/FoodBeverage.svg"
import automotive from "../../Component/image/mobileImages/automotive.svg"

const Foodbeverage = () => {
    const [screenWidth, setScreenWitdh] = useState(true)
    useEffect(() => {
        if (window.screen.width < 430) { setScreenWitdh(!screenWidth) }
    }, [])
    return (
        <div>
            {
                screenWidth ?
                    <div>
                        <Page_Discretelndustries1
                            title={
                                <>
                                    <span className="boldSpan">P</span>rocess{" "}
                                    <span className="boldSpan">I</span>ndustries
                                </>
                            }
                            body={
                                "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                            }
                        />

                        <Page_Discretelndustries4
                            imgurl1={img3}
                            title={
                                <>
                                    <span className="boldSpan">F</span>ood{" "}
                                    <span className="boldSpan">&</span>
                                    <span className="boldSpan"> B</span>everage
                                </>
                            }
                            imgurlonepD={imgurlEasyPD}
                            imgtext1={
                                "The food and beverage industry manages enormous amounts of data related to ingredients, formulas, test and trials, as well as intense competition and other challenges during the process."
                            }
                            imgurltwopD={imgurlsourcePD}
                            imgtext2={
                                "With ActionPLM, you can manage supplier collaboration and communication, speed up the R & D process, ensure compliance and other data from different departments. "
                            }
                            imgurlthreepD={imgurlWorkflowPD}
                            imgtext3={
                                "ActionPLM makes it easier to produce new products by solving these issues in a timely and efficient manner on a daily basis."
                            }
                        />
                    </div> :
                    <div>
                        <div>
                            <Page_Discretelndustries1
                                title={
                                    <>
                                        <span className="boldSpan">P</span>rocess{" "}
                                        <span className="boldSpan">I</span>ndustries
                                    </>
                                }
                                body={
                                    "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
                                }
                            />
                        </div>
                        <div>
                            <img src={FoodBeverage} width="100%" height="100%" />
                        </div>

                    </div>
            }
            {
                screenWidth ? <div>
                    <Page_Discretelndustries1
                        title={
                            <>
                                <span className="boldSpan">D</span>iscrete{" "}
                                <span className="boldSpan">I</span>ndustries
                            </>
                        }
                        body={
                            "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                        }
                    />

                    <Page_Discretelndustries2
                        title={
                            <>
                                <span className="boldSpan">A</span>utomotive{" "}
                            </>
                        }
                        imgurlone={imgurlEasy}
                        imgtext1={
                            "Traditional processes and systems are unable to fulfill changes in business requirements, to stay competitive and profitable."
                        }
                        imgurltwo={imgurlsource}
                        imgtext2={
                            "Product lifecycle management (PLM) software provides a framework for the automobile industry to integrate product design, life-cycle planning, assembly, testing, and maintenance. ActionPLM combines all PLM functions into a single package. "
                        }
                        imgurlthree={imgurlWorkflow}
                        imgtext3={
                            "Automotive firms use ActionPLM to integrate worldwide product development across all disciplines, manage complex BoMs, and support global supply and production chains."
                        }
                        imgurl={img2}
                        imgurl1={img1}
                        imgurlthree1={imgslider}
                        colorname="black"
                        classpara="classpara1"
                        idpara="idparaplm"
                        automotivebg_img="automotive_bg_img"
                        automotive_img_bg="automotive2"
                    />
                </div> :
                    <div>
                        <div>
                            <Page_Discretelndustries1
                                title={
                                    <>
                                        <span className="boldSpan">D</span>iscrete{" "}
                                        <span className="boldSpan">I</span>ndustries
                                    </>
                                }
                                body={
                                    "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
                                }
                            />
                        </div>
                        <div>
                            <img src={automotive} alt="alt" width="100%" height="100%" />
                        </div>
                    </div>
            }
        </div>
    );
}
export default Foodbeverage;
