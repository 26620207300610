import React, { useState, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import logo from "../image/logoName.svg";
import "./navBar.css";
import S_DropDown from "./S_DropDown";
import Indu_DropDown from "./Indu_DropDown";
import DropDown from "../image/dropdown.svg";
import mobile_home from "../image/mobileImages/Home.svg";
import industrie from "../image/mobileImages/industrie.svg";
import pricing from "../image/mobileImages/pricing.svg";
import Resource_management from "../image/mobileImages/Resource_management.svg";
import solutions from "../image/mobileImages/solutions.svg";
import getInTouch from "../image/mobileImages/Getintouch.svg"
import MobileMenu from "./MobileView/mobileMenu";
import menubar from "../image/mobileImages/menuIcon.svg"
import menuBr from "../image/mobileImages/menu-Icon.svg"
import Searchbar from "./MobileView/Searchbar/Searchbar";


const NavBar = ({ pageId }) => {

    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    const [changeImg, setChangeImg] = useState(false);

    const [Sdropdown, setSDropDown] = useState("none");
    const [I_dropdown, setI_dropdown] = useState("none");

    const [check, setCheck] = useState(false);
    const [mobileCheck, setMobileCheck] = useState(false)
    const [mobileCheck2, setMobileCheck2] = useState(false)

    //const [mobileCheck, setMobileCheck] = useState(false)
    const [id, setId] = useState('')

    const [active, setActive] = useState("");
    const [page, setpage] = useState(pageId)
    const [actives, setActives] = useState("");
    const closeDropDown = (e) => {
        setCheck(false)
    }

    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    // const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setI_dropdown(!I_dropdown);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // },[closeDropDown]);

    const handleClick = (e) => {
        setActive(window.localStorage.setItem('Pervious-State', JSON.stringify(e.target.id)))
        // console.log(e.target.tagName)
        if (e.target.id === "Solutions" && window.screen.width >= 430) {
            setCheck(!check)
            setSDropDown(!Sdropdown)
            setI_dropdown("none")
            setActives(!actives)
        } else if (e.target.id === "Solutions" && window.screen.width <= 430) {
            setMobileCheck(!mobileCheck)
            setMobileCheck2(false)
        } else if (e.target.id === "Industrie" && window.screen.width >= 430) {
            setActive("Industries");
            setActives("Industrie")
            setCheck(!check)
            setSDropDown("none")
            setI_dropdown(!I_dropdown)
        } else if (e.target.id === "Industries" && window.screen.width <= 430) {
            setMobileCheck2(!mobileCheck2)
            setMobileCheck(false)
            closeMobileDropDown()

        } else if (e.target.id === "Home" || e.target.id === "activeText" && window.screen.width <= 430) {
            document.getElementById("responsive-navbar-nav").classList.remove("show")
            document.getElementById("responsive-navbar").classList.add("collapsed")
            setSDropDown("none")
            setTimeout(() => {
                document.location.reload()
            }, 1000);
        } else {
            setCheck(check)
            setActives(!actives)
            setSDropDown("none")
            setI_dropdown("none")
            setMobileCheck(false)
            setMobileCheck2(false)
        }
    };

    const outSideClick = (e) => {
        if (e.target.tagName === "SECTION") {
            setCheck(false)
        }
    }
    const closeMobileDropDown = () => {
        document.getElementById("responsive-navbar-nav").classList.remove("show")
        document.getElementById("responsive-navbar").classList.add("collapsed")
        setChangeImg(!changeImg)
        setTimeout(() => {
            document.location.reload()
        }, 1000);
    }
    useEffect(() => {
        const data = window.localStorage.getItem('Pervious-State');
        setActive(JSON.parse(data));
    }, [active])

    return (
        <div className="Navbar_container">
            <Navbar
                expanded={expand}
                fixed="top"
                expand="md"
                className={navColour ? "sticky" : "navbar"}>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} className="img-fluid logo" alt="brand" width="100%" />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={() => {
                            updateExpanded(expand ? false : "expanded");
                            setChangeImg(!changeImg)
                        }}
                        id="responsive-navbar"
                    >
                        {
                            changeImg ? <img src={menuBr} alt="menubar" width="30px" height="30px" /> : <img src={menubar} alt="menubar" width="30px" height="30px" />
                        }
                    </Navbar.Toggle>

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Searchbar />
                            <Nav.Item id="MenuBar">
                                <Nav.Link>Menu</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/" id="Home" onClick={handleClick} className={active === "Home" ? "active" : ""}>
                                    <img src={mobile_home} alt="home" className="mobileIcon" />  Home
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} id="Solutions" className={`mobileActive  ${active === "Solutions" || page === "Part-Master" ? "active" : ""}`} onClick={handleClick}>
                                    <img src={solutions} alt="home" className="mobileIcon" />Solutions
                                    <img id="Solutions" src={DropDown} className={`dropIcon ${check && active === "Solutions" ? "roterIcon" : ''}`} alt="img" width="12px" onClick={handleClick} ref={dropdownRef} />
                                </Nav.Link>
                                <div className="" id="slut">
                                    <MobileMenu ClickID="Solutions"
                                        closeMobileDropDown={closeMobileDropDown}
                                    />
                                </div>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    to="Industries"
                                    id="Industries"
                                    className={`mobileActive ${active === "Industries" ? "active" : ""}`}
                                    onClick={handleClick}
                                >
                                    <img src={industrie} alt="home" className="mobileIcon" />
                                    Industries
                                </Nav.Link>
                                <img src={DropDown} className={`dropIcon ${check && actives === "Industrie" ? "roterIcon" : ''}`}
                                    id="Industrie" alt="img" width="12px" onClick={handleClick}
                                    ref={dropdownRef}
                                />
                                <div className="" id="slutIndustries">
                                    <MobileMenu ClickID="Industries"
                                        closeMobileDropDown={closeMobileDropDown}
                                    />
                                </div>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    id="Resources"
                                    className={active === "Resources" ? "active" : ""}
                                    onClick={handleClick}
                                >
                                    <img src={Resource_management} alt="home" className="mobileIcon" />
                                    Resources
                                    <img src={DropDown} className={`dropIcon ${check && active === "Resources" ? "roterIcon" : ''}`} alt="img" width="12px" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link
                                    as={Link}
                                    to="Pricing"
                                    id="Pricing"
                                    onClick={handleClick}
                                    className={active === "Pricing" ? "active" : ""}
                                >
                                    <img src={pricing} alt="home" className="mobileIcon" />
                                    Pricing
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item id="GETINTOUCH">
                                <Nav.Link
                                    as={Link}
                                    to="/contact-us"
                                    id="activeText"
                                    onClick={handleClick}
                                    className={active === "activeText" ? "active" : ""}
                                >
                                    <img src={getInTouch} alt="home" className="mobileIcon" />
                                    Get in Touch
                                </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link as={Link} to="/Contact-Us">
                                    Contact Us
                                </Nav.Link>
                            </Nav.Item> */}

                            <Link to="/contact-us" style={{ textDecoration: "none" }}>
                                <div className="login-btn">
                                    <div className={`border-gradient loginNav ${active === "activeText" ? "getIntouch" : ""}`} id="activelogin" onClick={handleClick}>
                                        <p id="activeText" className={active === "activeText" ? "getIntouchText" : ""}>Get in touch</p>
                                    </div>
                                </div>
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar >
            {
                check ?
                    <section style={{ width: "100%", height: "100vh", display: Sdropdown, position: "fixed", zIndex: "999" }} className="dropdown" onClick={outSideClick}>
                        <S_DropDown
                            onClick={closeDropDown}
                        />
                    </section>
                    : ''
            }
            {
                check ?
                    <section style={{ width: "100%", height: "100vh", display: I_dropdown, position: "fixed", zIndex: "999" }} className="dropdown" onClick={outSideClick}>
                        <Indu_DropDown onClick={closeDropDown} />
                    </section> : ''
            }
        </div >
    );
};

export default NavBar;
