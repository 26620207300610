import { useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import "./popUp.css";

const PopupExample = () => {
    const [show, setShow] = useState(false);
    const [Displaystatus, setDisplay] = useState(true);
    const [wait, setWait] = useState(true)
    const [screenWidth, setScreenWidth] = useState(true)
    const handleClose = () => {
        setShow(false)
        setDisplay(true)
        setWait(true)
        setFormData({ Fname: '', Lname: '', email: '' })
    };
    const handleShow = () => {
        setShow(true)
        setDisplay(true)
        if (window.screen.width <= 430) {
            setScreenWidth(false)
        }
    };
    const [formData, setFormData] = useState({ Fname: '', Lname: '', email: '' });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const backToHome = () => {
        setWait(true)
        setShow(false)
        setFormData({ Fname: '', Lname: '', email: '' })
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        setWait(false)
        //const apiKey = '4482D0ED0CA10F9AF4679220336B97DD3D18AFCE6EF68F5771B4D721C20B87F55A19D99368C879BE1CD982AB97983F16';
        const api = "2789E8555DDDAEE29F4CEF00700FB8AD223E6E869E703A3E7214AED132A6CB80B1631AB07886218272EDFC4CC7A0D763";
        const senderEmail = "support@kognics.com"
        const ReceverEmail = "s@kognics.com,dm@ActionPLM.com,siddiq@kognics.com,dm@kognics.com,selvi@kognics.com,vishal@kognics.com";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const message = `First Name: ${formData.Fname}\n
                         Last Name: ${formData.Lname} \n
                         Email: ${formData.email}`;

        try {
            //const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${apiKey}&subject=ActionPLM User Info&from=kkumar95973@gmail.com&to=vishal@kognics.com&bodyHtml=${message}`, requestOptions)
            const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${api}&subject=Lead Generated &from=${senderEmail}&to=${ReceverEmail}&bodyHtml=${message}`, requestOptions)
            if (res.status === 200) {
                setDisplay(false)
            }
        } catch (e) {
            alert(e)
        }
    }

    return (
        <>
            <div className="login-btn_home" onClick={handleShow}>
                <div className='Getdemo border-gradient'>
                    <div className='getdemoSpan'>Get a demo</div>
                </div>
            </div>
            {
                screenWidth ?

                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='modalStyle'
                    >
                        <Modal.Header closeButton className='closebtn_'></Modal.Header>
                        <Modal.Body>
                            <Container className='popUp-main'>
                                <div className='backgroundShap'>
                                    <div className='backgroundShap_inner1st'></div>
                                </div>
                                <Container style={Displaystatus ? { display: "block" } : { display: "none" }}>
                                    <div className='data-center'>
                                        <div className='data-cenetr-inner'>
                                            <div className='TopContent'>
                                                <div className='popupText'>
                                                    <h3>Get a <span>Demo !</span></h3>
                                                    <p className='mt-4'>
                                                        ActionPLM, helps you to modernize your business and create a digital
                                                        twin of your products and business.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='PopupContent'>
                                                <Form onSubmit={onSubmit}>
                                                    <div className='container data_container'>
                                                        <div className='data_input'>
                                                            <input placeholder='First Name' name='Fname' value={formData.Fname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <input placeholder='Last Name' name='Lname' value={formData.Lname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <input placeholder='Email' name='email' value={formData.email} onChange={handleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="login-btn_home login-btn_popup">
                                                        <div className='Getdemo border-gradient-popUp'>
                                                            <button className='loginBtn backToHome btn' type='submit'>{wait ? "Submit" : "Please Wait .."}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                                <div style={Displaystatus ? { display: "none" } : { display: "block" }} className="Second-Section">
                                    <div className='mt-4'>
                                        <div className='text-center' >
                                            <h3>
                                                <span>Thank you</span> for submitting your request
                                            </h3>
                                            <p>We will get in touch with you shortly</p>
                                        </div>
                                        <div className="login-btn_home login-btn_popup">
                                            <div className='Getdemo border-gradient-popUp'>
                                                <button className='loginBtn backToHome btn' onClick={backToHome}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='backgroundShap_2nd'>
                                    <div className='backgroundShap_inner'></div>
                                </div>
                            </Container>
                        </Modal.Body>
                    </Modal>
                    :
                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='modalStyle_mobile'
                    >
                        <Modal.Header closeButton className='closebtn_'></Modal.Header>
                        <Modal.Body>
                            <Container className='popUp-main'>
                                <div className='backgroundShap'>
                                    <div className='backgroundShap_inner1st'></div>
                                </div>
                                <Container style={Displaystatus ? { display: "block" } : { display: "none" }}>
                                    <div className='data-center'>
                                        <div className='data-cenetr-inner'>
                                            <div className='TopContent'>
                                                <div className='popupText'>
                                                    <h3>Get a <span>Demo !</span></h3>
                                                    <p className='mt-4'>
                                                        ActionPLM, helps you to modernize your business and create a digital
                                                        twin of your products and business.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='PopupContent'>
                                                <Form onSubmit={onSubmit}>
                                                    <div className='container data_container'>
                                                        <div className='data_input'>
                                                            <input placeholder='First Name' name='Fname' value={formData.Fname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <input placeholder='Last Name' name='Lname' value={formData.Lname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <input placeholder='Email' name='email' value={formData.email} onChange={handleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="login-btn_home login-btn_popup">
                                                        <div className='Getdemo border-gradient-popUp'>
                                                            <button className='loginBtn backToHome btn' type='submit'>{wait ? "Submit" : "Please Wait .."}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                                <div style={Displaystatus ? { display: "none" } : { display: "block" }} className="Second-Section">
                                    <div className='mt-4'>
                                        <div className='text-center' >
                                            <h3>
                                                <span>Thank you</span> for submitting your request
                                            </h3>
                                            <p>We will get in touch with you shortly</p>
                                        </div>
                                        <div className="login-btn_home login-btn_popup">
                                            <div className='Getdemo border-gradient-popUp'>
                                                <button className='loginBtn backToHome btn' onClick={backToHome}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='backgroundShap_2nd'>
                                    <div className='backgroundShap_inner'></div>
                                </div>
                            </Container>
                        </Modal.Body>
                    </Modal>
            }


        </>
    );
}

export default PopupExample