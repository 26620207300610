import React from 'react'
import { Container } from 'react-bootstrap'
import "./style.css"
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import SliderCard from './SliderCard';
import security from "../../image/security.svg";
import productivily from "../../image/productivily.svg";
import innovation from "../../image/innovation.svg";
import market from "../../image/market.svg";
import experience from "../../image/experience.svg"
import dataAccess from "../../image/dataAccess.svg"
import developmentProcess from "../../image/developmentProcess.svg"
import TCO from "../../image/TCO.svg"
import ImproveBottomLine from "../../image/ImproveBottomLine.svg"
import ImproveProductQuality from "../../image/ImproveProductQuality.svg"
import ChainCollaboration from "../../image/ChainCollaboration.svg"
import RegulatoryCompliance from "../../image/RegulatoryCompliance.svg"


const WhatmakeUS = () => {
    const swiperRef = React.useRef(null);
    const swiperLRef = React.useRef(null);

    const sliderCards = [
        {
            icon: `${security}`,
            content: `<p class="textAlignment">Eliminating Security Risk</p>`,
        },
        {
            icon: `${productivily}`,
            content: `<p class="textAlignment">Increase In Productivity</p>`,
        },
        {
            icon: `${innovation}`,
            content: `<p class="textAlignment">Drive Innovation</p>`,
        },
        {
            icon: `${market}`,
            content: `<p class="textAlignment">Faster Time To Market</p>`,
        },
        {
            icon: `${experience}`,
            content: `<p class="textAlignment">Better Customer Experience</p>`,
        },


        {
            icon: `${developmentProcess}`,
            content: "Simplified Product Development Process",
        },
        {
            icon: `${TCO}`,
            content: "Reducing The Total Cost Of Ownership (TCO)",
        },
        {
            icon: `${RegulatoryCompliance}`,
            content: "Simplifying Regulatory Compliance",
        },
        {
            icon: `${ChainCollaboration}`,
            content: "Improving Supply Chain Collaboration",
        },
        {
            icon: `${ImproveProductQuality}`,
            content: "Improved Product Quality",
        },
        {
            icon: `${dataAccess}`,
            content: "Easy Data Access & Collaboration",
        },
        {
            icon: `${ImproveBottomLine}`,
            content: "Improved Bottom Line",
        },
    ]


    return (
        <div className='section_benefit'>
            <div className='key_benefit'>
                <h2> <span className='keybnf'>What makes us</span> Better!</h2>
                <p className='mt-4'>We support a client's problem in developing high-quality products with:</p>
            </div>
            <div className='sliderMainMiddel'>
                <div className='slider_middleHome_right'>
                    <div className='slider_middleinner_right'>
                        <div className='customSlider'>
                            <Swiper
                                ref={swiperRef}
                                slidesPerView={4}
                                spaceBetween={270}
                                loop
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 280
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 150
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 20
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 180
                                    },
                                    1280: {
                                        slidesPerView: 4,
                                        spaceBetween: 370
                                    },
                                    2000: {
                                        slidesPerView: 4,
                                        spaceBetween: 30
                                    }

                                }}
                                className="mySwiper">
                                {
                                    sliderCards.slice(0, 5).map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <SliderCard
                                                    icon={item.icon}
                                                    content={item.content}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <div className='Navigation'>
                                <div id="ArrowButton" onClick={() => swiperRef.current.swiper.slideNext()}>
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#3865AD" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='middelContent'>
                    <div className='backgroundColor'>

                    </div>
                    <div className='guideText'>
                        <p className='textFont'>This guides to:</p>
                    </div>
                </div>
                <div className='slider_middleHome_left'>
                    <div className='slider_middleinner_left'>
                        <div className='customSlider customSlider-right'>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                ref={swiperLRef}
                                loop
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 250
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 150
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 20
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 180
                                    },
                                    1280: {
                                        slidesPerView: 4,
                                        spaceBetween: 370
                                    },
                                    2000: {
                                        slidesPerView: 4,
                                        spaceBetween: 30
                                    }

                                }}
                            >
                                {
                                    sliderCards.slice(5, sliderCards.length).map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <SliderCard
                                                    icon={item.icon}
                                                    content={item.content}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <div className='Navigation_left Navigation'>
                                <div id="ArrowButton" onClick={() => swiperLRef.current.swiper.slideNext()}>
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#3865AD" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatmakeUS