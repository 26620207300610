import React from "react";
import img1 from "../image/inventory_management_img.svg";

import box from "../image/Inventory_Management_firstcard.svg";
import Req_two from "../image/inventory_management_twocard_img.svg";

import Group_one from "../image/Ms.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image//inventory_management_storage_img.svg";
import Group_bell from "../image/inventory_management_stockupdate_img.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_eight_Main_file() {
  const svgFrt = (
    
    <svg
      className="svgeight"
      viewBox="0 0 999 824"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      className="path" fill="none"
      stroke="#5C90C4"
      stroke-width="4"
      stroke-linejoin="round"
      stroke-miterlimit="40"
        d="M397.956 101.034C294.956 -129.468 -181.073 84.3512 78.4562 315.243C150.957 379.743 407.956 372.534 634.834 219.233C962.044 -1.86227 1081.46 261.732 932.956 315.243C779.003 370.718 664.881 322.508 397.956 503.743C86.4564 715.243 492.5 949.034 949 739.534"
       
      />
      <path
      path fill="none"
    stroke="white"
    stroke-linejoin="round"
    stroke-miterlimit="40" 
    stroke-width="4"
    className="dashed"
        d="M397.956 101.034C294.956 -129.468 -181.073 84.3512 78.4562 315.243C150.957 379.743 407.956 372.534 634.834 219.233C962.044 -1.86227 1081.46 261.732 932.956 315.243C779.003 370.718 664.881 322.508 397.956 503.743C86.4564 715.243 492.5 949.034 949 739.534"
        
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page8_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page8_p2").style.display = "block";
  }, 2600);


  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">I</span>nventory{" "}
            <span className="boldSpan">M</span>anagement{" "}
            </> 
        }
        body={
          "ActionPLM offers an inventory management system that allows you to record all inventory data as well as their storage location. ActionPLM gives you complete insight and control over the movement of items among your various locations, allowing you to boost customer satisfaction while lowering costs and working capital investment."
        }
        imgurl={img1}
        changemanagmentimg1="changemanagment_img"
        changemanagmentimg="change_managment_img"
      />

      <Page2
        classes={["Page8_p1", "Page8_p2"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
        
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Maintaining Stocks"}
        bottom_two_P={"Storage Details"}
        bottom_three_P={"Real-Time  Stock Update"}
        fved="groupbellicon"
      />
    </div>
  );
}

export default plm_eight_Main_file;
