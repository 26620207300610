import React from 'react'

const SliderCard = ({ icon, content }) => {
    return (
        <div className='cardslider'>
            <div className='CardInner border-gradient-2nd'>
                <div className='iconOuter'>
                    <div className='iconInner'>
                        <img src={icon} alt='icon' width="75px" height="75px" />
                    </div>
                </div>
                <p className='textFont' dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    )
}

export default SliderCard