import React from "react";
import img1 from "../image/Test_&_Trials_img.svg";

import box from "../image/Test_&_Trials_cardone.svg";
import Req_two from "../image/Test_&_Trials_cardtwos.svg";

import Group_one from "../image/Test_&_Trials_DataManagment.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/Test_&_Trials_Structured.svg";
import Group_bell from "../image/Test_&_Trials_Comparison.svg";

import Page_one from "./Page_PLM_partone";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_thirteen_Main_file() {
  const svgFrt = (
    <svg
      className="svgthirteen"
      viewBox="0 0 1035 823"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M470.841 135.499C238.341 -178.001 -190.282 132.489 98.7184 366.49C153.1 410.522 327.738 442.261 668.719 247.991C1011.84 52.5001 1115.34 290.49 966.841 344C812.888 399.476 557.341 366.49 431.841 532.5C204.784 832.849 794.341 903 914.341 720.5"
        stroke="#8A639A"
        stroke-width="4"
        className="path "
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M470.841 135.499C238.341 -178.001 -190.282 132.489 98.7184 366.49C153.1 410.522 327.738 442.261 668.719 247.991C1011.84 52.5001 1115.34 290.49 966.841 344C812.888 399.476 557.341 366.49 431.841 532.5C204.784 832.849 794.341 903 914.341 720.5"
        stroke-width="4"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page13_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page13_p2").style.display = "block";
  }, 2700);

  return (
    <div>
      <Page_one
        imgurl={img1}
        changemanagmentimg="changemanagment_img"
        changemanagmentimg1="change_managment_img"
        title={
          <>
            <span className="boldSpan">T</span>est{" "}
            <span className="boldSpan"> & </span>
            <span className="boldSpan">T</span>rials
          </>
        }
        body={
          "ActionPLM test & trial assists customers in finding the optimal formula for their product using past formulations data and new data, helps in cost reduction and better formula for the product by performing a number of tests and trials."
        }
      />

      <Page2
        classes={["Page13_p1", "Page13_p2"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Efficient Formula Data Management"}
        bottom_two_P={"Structured Approval Workflow"}
        bottom_three_P={"Better Comparison Between Formulas"}
      />
    </div>
  );
}

export default plm_thirteen_Main_file;
