import React from "react";
import img1 from "../image/ingredient_Managment_img.svg";
import img_bg from "../image/Rectangle_bg.svg";
import box from "../image/Ingredient_Management_cardone.svg";
import Req_two from "../image/Ingredient_Management_cardtwo.svg";

import Group_one from "../image/Ingredient_Management_Master.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/Ingredient_Management_Attributes.svg";
import Group_bell from "../image/Ingredient_Management_Synchronisation.svg";

import Page_one from "./Page_PLM_partone";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";

function plm_one_Main_file() {
  const svgFrt = (
    <svg
      className="svgeleven"
      viewBox="0 0 1035 823"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M470.841 135.499C238.341 -178.001 -190.282 132.489 98.7184 366.49C153.1 410.522 327.738 442.261 668.719 247.991C1011.84 52.5001 1115.34 290.49 966.841 344C812.888 399.476 557.341 366.49 431.841 532.5C204.784 832.849 794.341 903 914.341 720.5"
        stroke="#8A639A"
        stroke-width="4"
        className="path "
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M470.841 135.499C238.341 -178.001 -190.282 132.489 98.7184 366.49C153.1 410.522 327.738 442.261 668.719 247.991C1011.84 52.5001 1115.34 290.49 966.841 344C812.888 399.476 557.341 366.49 431.841 532.5C204.784 832.849 794.341 903 914.341 720.5"
        stroke-width="4"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page11_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page11_p2").style.display = "block";
  }, 2700);

  return (
    <div>
      <Page_one
        titleplmone="titleleven"
        imgurl={img1}
        imgurl1={img_bg}
        automotivebg_img=" automotive_bg_img"
        changemanagmentimg="changemanagment_img"
        changemanagmentimg1="change_managment_img"
        title={
          <>
            <span className="boldSpan">I</span>ngredient{" "}
            <span className="boldSpan">M</span>anagement
          </>
        }
        body={
          "In every process industry, a large number of ingredients are used. ActionPLM allows you to handle all of the details related to ingredients, define attributes and classify all of the ingredients based on your needs. It is also capable of managing modifications and visualize cost rollups."
        }
      />

      <Page2
        classes={["Page11_p1", "Page11_p2"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Detail Ingredient Master"}
        bottom_two_P={"User-Defined Attributes"}
        bottom_three_P={"Supplier Synchronisation"}
      
      />
    </div>
  );
}

export default plm_one_Main_file;
