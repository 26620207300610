import React from "react";
import img1 from "../image/BOM_img_Main.svg";

import box from "../image/BOM_one_card_img.svg";
import Req_two from "../image/BOM_two_card_img.svg";
import Req_three from "../image/BOM_three_card_img.svg";

import Group_one from "../image/BOM_Approval_img.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/change_managment_cost_Rollup_img_.svg";
import Group_bell from "../image/CAD-BOM_img.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_four_Main_file() {
  const svgFrt = (
    <svg
      className="svgfour"
      viewBox="0 0 949 1181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke="#5C90C4"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
        className="path "
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page4_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page4_p2").style.display = "block";
  }, 2200);

  setTimeout(() => {
    document.querySelector(".Page4_p3").style.display = "block";
  }, 2900);
  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">B</span>ill{" "}
            <span className="boldSpan">O</span>f{" "}
            <span className="boldSpan">M</span>aterials{" "}
            <span className="boldSpan">(BOM)</span>
          </>
        }
        body={
          "ActionPLM helps industries to link and control several data sources in a single database. Each step of the product life cycle may have an organised, multi-level bill of materials that is simple to generate and manage. For a clear view of cross-disciplinary change statuses across design, production, and service, ActionPLM makes it easy to synchronise   (Engineering bill of Material) EBOMs with (Manufacturing bill of Material)MBOMs."
        }
        imgurl={img1}
        changemanagmentimg="imgchangemag"
        changemanagmentimg1="changemanagment_img"

      />

      <Page2
        classes={["Page4_p1", "Page4_p2", "Page4_p3"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Approval Based Released"}
        bottom_two_P={"Cost Rollup Management"}
        bottom_three_P={"CAD - BOM Synchronisation"}
      />
    </div>
  );
}

export default plm_four_Main_file;
