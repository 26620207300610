import React from "react";
import img1 from "../image/supplier_managment_img.svg";

import box from "../image/supplier_cardone.svg";
import Req_two from "../image/Supplier_Management_cardtwo.svg";
import Req_three from "../image/supplier_cardthree.svg";

import Group_one from "../image/supplier_Details_img.svg";
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/supplier_RFQ_img.svg";
import Group_bell from "../image/supplier_Feedback_img.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_six_Main_file() {
  const svgFrt = (
    <svg
      className="svgsix"
      viewBox="0 0 1024 1198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path1"
        fill="none"
        stroke="#5C90C4"
        stroke-width="4"
        stroke-linejoin="round"
        d="M430.479 90.7082C327.479 -139.793 -201.645 125.198 87.3554 359.198C141.737 403.23 316.375 434.969 657.356 240.699C1000.48 45.2086 1103.98 283.198 955.478 336.709C801.525 392.184 357.5 430.492 357.5 637.5C357.5 778 753.359 587.701 749 722.5C900.479 944 8 1336 900.479 1143.5"
      />

      <path
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        stroke-width="4"
        className="dashed1"
        d="M430.479 90.7082C327.479 -139.793 -201.645 125.198 87.3554 359.198C141.737 403.23 316.375 434.969 657.356 240.699C1000.48 45.2086 1103.98 283.198 955.478 336.709C801.525 392.184 357.5 430.492 357.5 637.5C357.5 778 753.359 587.701 749 722.5C900.479 944 8 1336 900.479 1143.5"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page6_p1").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Page6_p2").style.display = "block";
  }, 2200);

  setTimeout(() => {
    document.querySelector(".Page6_p3").style.display = "block";
  }, 3200);
  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">S</span>upplier{" "}
            <span className="boldSpan">M</span>anagement{" "}
          </>
        }
        body={
          "With ActionPLM, now bring your suppliers in one, easily maintainable location. With ActionPLM’s automations and simplification of important activities such as onboarding new suppliers, exchanging product data and evaluating product quality and supplier performance have a direct influence on the business's profitability and bottom line."
        }
        imgurl={img1}
        changemanagmentimg1="changemanagment_img"
        changemanagmentimg="change_managment_img"
      />

      <Page2
        classes={["Page6_p1", "Page6_p2", "Page6_p3"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Supplier Details"}
        bottom_two_P={"RFQ In Single Click"}
        bottom_three_P={"Supplier Feedback"}
      />
    </div>
  );
}

export default plm_six_Main_file;
