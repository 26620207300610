import React from "react";

import img1 from "../image/Rich_Analytics.svg";
import img2 from "../image/richmangement.svg";
import imgurlEasy from "../image/QualityManagementicon.svg";
import imgurlid3 from "../image/Rich_Analytics_IMG1.svg";
import imgurlsource from "../image/RichAnalytics_Time.svg";
import imgurlWorkflow from "../image/RichAnalytics_improve.svg";

import box from "../image//Rich_Analytics_img.svg";
import Req_two from "../image/Rich_Analytics_cardtwo.svg";

import Page_partplmone from "../Page_partplmone";
import Page_partplmtwo from "../Page_partplmtwo";
/*import Page_partplmthree from "../Page_partplmthree";*/
import Page_partplmfour from "../Page_partplmfour";


function plm_threeone_Main_file() {
  const svgFrt = (
    <svg
      className="svgone1"
      viewBox="0 0 949 1181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke="#5C90C4"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
        stroke-dasharray="8 8"
        className="path "
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
        stroke-dasharray="8 8"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Req_onep").style.display = "block";
  }, 0);

  setTimeout(() => {
    document.querySelector(".Req_twop").style.display = "block";
  }, 2500);

  /* setTimeout(() => {
         document.querySelector(".Req_three").style.display = "block";*/
       
       
  return (
    <div>
      <Page_partplmone
        title={
          <>
            <span className="boldSpanone">R</span>ich &nbsp; {" "}
            <span className="boldSpanone">A</span>nalytics
          </>
          
        }
        body={
          "ActionPLM offers business-ready analytics solutions that eliminates time-consuming process and creates user-friendly and dynamic dashboards in just a few clicks, allowing users to drill into whatever data they need. This enables your team to quickly acquire relevant insights about product and quality processes in order to make better decisions."
        }
        imgurl={img1}
        classtitle="title1"
        Id_IMG="Integration_Management"
        IDLE_IMG="Integration_Management1"
        imgurlid={imgurlid3}
        
      />
      <Page_partplmtwo
        imgurlone={imgurlEasy}
        imgtext1={"Quality Report"}
        imgurltwo={imgurlsource}
        imgtext2={"Real Time Data Visualization"}
        imgurlthree={imgurlWorkflow}
        imgtext3={"Improve Visibility & Traceability"}
        classnamepara="parasqure"
        imgurl={img2}
        classnameicon="icon3"
        classnamepara1="classtitle"
        classnamepara2="classtitles"
        classicon="iconquality"
        section_text="section-text"
        secation_Part="secation-Part"
        classnameicon1="icon_IMG"
        classnameicon2="icon_text" 
        content_box="content_text"
        image_text="image_div"
      />
        
      

      <Page_partplmfour
        classes={["Req_onep", "Req_twop"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
      /* imgurl_three={Req_three}*/
      />
      </div>
);
}
  
    


export default plm_threeone_Main_file;
