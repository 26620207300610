import React, { useEffect } from 'react'
import search_icon from "../../../image/mobileImages/serachbar.svg"
import Nav from "react-bootstrap/Nav";
import { Link } from 'react-router-dom';

const Searchbar = () => {

    let names = [
        "Automotive",
        "Industrial-Manufacturing",
        "Aerospace-Defence",
        "Electric-Vehicles",
        "Food-Beverages",
        "Cosmetics",
        "Pharmaceuticals",
        "Textile",
        "Requirement-Management",
        "Part-Master",
        "Change-Management",
        "Bill-Material",
        "File-Management",
        "Supplier-Management",
        "Quality-Management",
        "Inventory-Management",
        "Manufacturing Bill of Material",
        "BOM-Configuration",
        "Ingredient-Management",
        "Formulation-Management",
        "Test-Trials",
        "Artwork-Labelling",
        "Integration-Management",
        "Workflow-Management",
        "Rich-Analytics",
        "Permission-based-access"
    ]

    let Search = document.getElementById("search")

    function displayName(value) {
        Search.value = value
    }
    const removeElements = () => {
        let items = document.querySelectorAll(".list-items")
        items.forEach((item) => {
            item.remove()
        })
    }
    const searchItem = (e) => {
        removeElements()
        for (let i of names.sort()) {
            if (i.toLocaleLowerCase().startsWith(Search.value.toLocaleLowerCase()) && Search.value !== "") {
                let listItem = document.createElement("a");
                listItem.classList.add("list-items");
                listItem.setAttribute("href", i)
                listItem.setAttribute("onclick", "displayName(`" + i + "`)");
                let word = i.substr(Search.value.lenght);
                listItem.innerHTML = word
                document.querySelector(".listMenuBar").appendChild(listItem)
            }
        }
    }


    return (
        <>
            <Nav.Item id="searchInput">
                <input placeholder="Search" id='search' onKeyUp={searchItem} />
                <img src={search_icon} id="Searchicon" />
            </Nav.Item>
            <div className='listMenuBar'></div>
        </>
    )
}

export default Searchbar