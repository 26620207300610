import React from "react";
import "./Page_DiscreteIndustries1.css";
function Page_DiscreteIndustries1({ title, body }) {
  return (
    <div className="sectionDi">
      <div className="content-sectionDi">
        <h1> {title}</h1>

        <p>{body}</p>
      </div>
    </div>
  );
}
export default Page_DiscreteIndustries1;
