import React from "react";
import "./Page_PLM_partone.css";
function Page_one({ title, body, imgurl ,changemanagmentimg1,changemanagmentimg}) {
  return (
    <div className="section">
      <div className="image-section">
      
        <img id={changemanagmentimg1} src={imgurl} alt="" />
      </div>
      <div className="contentone-section">
        <h1 className="titleplmone">{title}</h1>
        <div id={changemanagmentimg} className="image-section">
        <img src={imgurl} alt="" />
      </div>
        <p>{body}</p>
      </div>
    </div>
  );
}

export default Page_one;
