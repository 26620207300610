import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./getInTouch.css"
import getInTouch from '../../image/getInTouch.png';

const GetInTouch = () => {

    const [Displaystatus, setDisplay] = useState(true);

    const [wait, setWait] = useState(true)
    const [formData, setFormData] = useState({ Fname: '', Lname: '', email: '', message: '' });

    const goTohome = () => {
        setFormData({ Fname: '', Lname: '', email: '', message: '' })
        window.location.reload()
        Displaystatus(true)
        setWait(true)
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const onSubmit = async (e) => {
        e.preventDefault()
        setWait(false)
       // const apiKey = '4482D0ED0CA10F9AF4679220336B97DD3D18AFCE6EF68F5771B4D721C20B87F55A19D99368C879BE1CD982AB97983F16';
        const api = "2789E8555DDDAEE29F4CEF00700FB8AD223E6E869E703A3E7214AED132A6CB80B1631AB07886218272EDFC4CC7A0D763";
        const senderEmail = "support@kognics.com"
        const ReceverEmail = "s@kognics.com,dm@ActionPLM.com,siddiq@kognics.com,dm@kognics.com,selvi@kognics.com,vishal@kognics.com";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const message = `First Name: ${formData.Fname}\n
                         Last Name: ${formData.Lname} \n
                         Email: ${formData.email} \n
                         Message: ${formData.message}`;

        try {
            //const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${apiKey}&subject= ActionPLM User Info &from=kkumar95973@gmail.com&to=vishal@kognics.com&bodyHtml=${message}`, requestOptions)
            const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${api}&subject=Lead Generated &from=${senderEmail}&to=${ReceverEmail}&bodyHtml=${message}`, requestOptions)
            if (res.status === 200) {
                setDisplay(false)
            }
        } catch (e) {
            alert(e)
        }
    }



    return (
        <div className='Contactus'>
            <div className='imgSection-contact'>
                <img src={getInTouch} className="fluid" width="100%" />
            </div>
            <div className='ContentSectionForm'>
                <Container className='TilteSection-Form'>
                    <h3>Get in <span>Touch</span></h3>
                    <p>ActionPLM, helps you to modernize your business and create a digital twin of your products and business.</p>
                </Container>
                <Container className='ContentSection-contact'>
                    <Form className='contactForm' onSubmit={onSubmit} style={Displaystatus ? { display: "block" } : { display: "none" }}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control type="text" placeholder="First Name" name='Fname' value={formData.Fname} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control type="text" placeholder="Last Name" name='Lname' value={formData.Lname} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3 formTextare" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={5} placeholder="Message" name='message' value={formData.message} onChange={handleChange} />
                        </Form.Group>
                        <div className="login-btn_home getInTouch">
                            <div className='Getdemo border-gradient'>
                                <button className='loginBtn backToHome btn'>{wait ? "Submit" : "Please Wait .."}</button>
                            </div>
                        </div>
                    </Form>
                    <div style={Displaystatus ? { display: "none" } : { display: "block" }}>
                        <div className='mt-4'>
                            <div className='text-center' >
                                <h3>
                                    <span>Thank you</span> for submitting your request
                                </h3>
                                <p>We will get in touch with you shortly</p>
                            </div>
                            <div className="login-btn_home login-btn_popup">
                                <div className='Getdemo border-gradient-popUp'>
                                    <button className='loginBtn backToHome btn' onClick={goTohome}>Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default GetInTouch;