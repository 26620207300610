import React from 'react'

const GetInTouch = () => {
    return (
        <div className='GetInTouch'>
            <div className='ServiceInner'>
                <h4 className="pt-4">Get In Touch</h4>
                <ul>
                    <li>
                        <div className='adj-icons'>
                            <span>
                                <i class="fa-solid fa-location-dot"></i>
                            </span>
                            <span>Plot No.8, Govind Marg, Malviya <br />Nagar, Jaipur, Rajasthan 302017</span>
                        </div>
                    </li>
                    <li>
                        <div className='adj-icons'>
                            <span><i className="fa-solid fa-envelope"></i></span>
                            <span>
                                dm@actionplm.com
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className='adj-icons'>
                            <span><i className="fa-sharp fa-solid fa-phone"></i></span>
                            <span>
                                +91 7878106602

                            </span>
                        </div>
                    </li>
                    <li>
                        <div className='Footer-icons'>
                            <a href="https://www.facebook.com/kognics/">
                                <div className='circle'><i className="fa-brands fa-facebook-f"></i>
                                </div>
                            </a>
                            <a href='https://twitter.com/Kognics'>
                                <div className='circle'><i className="fa-brands fa-twitter">
                                </i></div>
                            </a>
                            <a href='https://www.instagram.com/kognics/'>
                                <div className='circle'><i className="fa-brands fa-instagram">
                                </i></div>
                            </a>
                            <a href='https://www.linkedin.com/company/ActionPLM/'>
                                <div className='circle'><i className="fa-brands fa-linkedin-in">
                                </i></div>
                            </a>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default GetInTouch