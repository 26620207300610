import React, { useEffect, useState } from 'react'
import mobileCheck from "../../image/mobileImages/data_line.png"
import DropDown from "../../image/dropdown.svg";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import mobileIcon from '../../image/mobileImages/checkbox.svg'


const MobileMenu = ({ ClickID, ClickID2, closeMobileDropDown }) => {
    const [mobileCheck, setMobileCheck] = useState(false)
    const [id, setId] = useState('')

    const mobileHandelClick = (e) => {
        document.querySelector(".mobileView").style.height = "auto"
        if (e.target.id === "DiscreteMobile") {
            setMobileCheck(!mobileCheck)
            setId(e.target.id)
        } else if (e.target.id === "ProcessMobile") {
            setMobileCheck(!mobileCheck)
            setId(e.target.id)
        } else if (e.target.id === "OtherMobile") {
            setMobileCheck(!mobileCheck)
            setId(e.target.id)
        }
    }




    return (
        <Container className='mobileView' id='mobileViewSolution'>
            {
                ClickID === "Solutions" ?
                    <ul>
                        <li className='' id="DiscreteMobile" onClick={mobileHandelClick} style={id === "DiscreteMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
                            <img src={mobileIcon} width="10px" height="10px" className='iconMobile' />DiscretePLM<img id="DiscretePLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "DiscreteMobile" ? 'roterIcon' : ''}`} alt="img" />
                        </li>
                        {
                            mobileCheck && id === "DiscreteMobile"
                                ?
                                <div className='listMenuBar'>
                                    <ul>
                                        <li><Link onClick={closeMobileDropDown} to="Requirement-Management">Requirement Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Part-Master">Part Master</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Change-Management">Change Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Bill-Material">Bill of Material</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="File-Management">File Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Supplier-Management">Supplier Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Quality-Management">Quality Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="Inventory-Management">Inventory Management</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="MBOM">Manufacturing Bill of Material (MBOM)</Link></li>
                                        <li><Link onClick={closeMobileDropDown} to="BOM-Configuration">BOM Configuration</Link></li>
                                    </ul>
                                </div>
                                : null
                        }
                        <li className='' id='ProcessMobile' onClick={mobileHandelClick} style={id === "ProcessMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
                            <img src={mobileIcon} width="10px" height="10px" className='iconMobile' />Process PLM
                            <img id="ProcessPLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "ProcessMobile" ? 'roterIcon' : ''}`} alt="img" />
                        </li>
                        {
                            mobileCheck && id === "ProcessMobile"
                                ?
                                <div className='listMenuBar'>
                                    <ul>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Ingredient-Management">Ingredient Management</Link>
                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Formulation-Management">Formulation Management</Link>

                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Test-Trials">Test & Trials</Link>

                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Artwork-Labelling">Artwork & Labelling</Link>
                                        </li>
                                    </ul>
                                </div>
                                : null
                        }
                        <li className='' id='OtherMobile' onClick={mobileHandelClick} style={id === "OtherMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
                            <img src={mobileIcon} width="10px" height="10px" className='iconMobile' />
                            Others
                            <img id="OthersPLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "OtherMobile" ? 'roterIcon' : ''}`} alt="img" />
                        </li>
                        {
                            mobileCheck && id === "OtherMobile"
                                ?
                                <div className='listMenuBar'>
                                    <ul>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Integration-Management">Integration Management</Link>
                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Workflow-Management">Workflow Management</Link>
                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Rich-Analytics">Rich Analytics</Link>
                                        </li>
                                        <li>
                                            <Link onClick={closeMobileDropDown} to="Permission-based-access">Permission Based Access</Link>
                                        </li>
                                    </ul>
                                </div>
                                : null
                        }
                    </ul> : null
            }

            {
                ClickID === "Industries" ? <ul>
                    <li className='' id="DiscreteMobile" onClick={mobileHandelClick} style={id === "DiscreteMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
                        <img src={mobileIcon} width="10px" height="10px" className='iconMobile' />Discrete <img id="DiscretePLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "DiscreteMobile" ? 'roterIcon' : ''}`} alt="img" />
                    </li>
                    {
                        mobileCheck && id === "DiscreteMobile"
                            ?
                            <div className='listMenuBar'>
                                <ul>
                                    <li><Link onClick={closeMobileDropDown} to="Automotive">Automotive</Link></li>
                                    <li><Link onClick={closeMobileDropDown} to="Industrial-Manufacturing">Industrial Manufacturing</Link></li>
                                    <li><Link onClick={closeMobileDropDown} to="Aerospace-Defence">Aerospace & Defence</Link></li>
                                    <li><Link onClick={closeMobileDropDown} to="Electric-Vehicles">Electric Vehicles</Link></li>
                                </ul>
                            </div>
                            : null
                    }
                    <li className='' id='ProcessMobile' onClick={mobileHandelClick} style={id === "ProcessMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
                        <img src={mobileIcon} width="10px" height="10px" className='iconMobile' />Process
                        <img id="ProcessPLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "ProcessMobile" ? 'roterIcon' : ''}`} alt="img" />
                    </li>
                    {
                        mobileCheck && id === "ProcessMobile"
                            ?
                            <div className='listMenuBar'>
                                <ul>
                                    <li>
                                        <Link onClick={closeMobileDropDown} to="Food-Beverages">Food and Beverages</Link>
                                    </li>
                                    <li>
                                        <Link onClick={closeMobileDropDown} to="Cosmetics">Cosmetics</Link>

                                    </li>
                                    <li>
                                        <Link onClick={closeMobileDropDown} to="Pharmaceuticals">Pharmaceuticals</Link>

                                    </li>
                                    <li>
                                        <Link onClick={closeMobileDropDown} to="Textile">Textile</Link>
                                    </li>
                                </ul>
                            </div>
                            : null
                    }
                </ul>
                    : null
            }

        </Container >
    )
}

export default MobileMenu





// {
//     array.map((item, index) => {

//         return (
//             <li className='' id={item.id} onClick={() => mobileHandelClick(item.data, item.id)} style={id === "DiscreteMobile" ? { textDecoration: "underline", background: "rgba(244, 246, 250, 0.5)" } : null}>
//                 {item.title}
//                 <img id="DiscretePLM" src={DropDown} width="12px" className={`mobileDropDown ${mobileCheck && id === "DiscreteMobile" ? 'roterIcon' : ''}`} alt="img" />
//                 {
//                     mobileCheck && id === "DiscreteMobile" ? Array.map((item, index) => {
//                         return (
//                             <div className='listMenuBar'>
//                                 <ul>
//                                     <li><Link onClick={closeMobileDropDown} to="Requirement-Management">{item.title}</Link></li>
//                                 </ul>
//                             </div>
//                         )
//                     }) : ''
//                 }
//             </li>
//         )
//     })
// }

// array={[
//     {
//         id: 'DiscreteMobile',
//         title: "DiscretePLM",
//         data: ["Requirement Management", "Part Master", "Change Management",
//             "Bill of Material", "File Management", "Supplier Management", "Quality Management",
//             "Inventory Management", "Manufacturing Bill of Material (MBOM)", "BOM Configuration"]
//     },
//     {
//         title: "ProcessPLM",
//         data: ["Ingredient Management", "Formulation Management", "Test & Trials", "Artwork & Labelling"]
//     },
//     {
//         title: "Other",
//         data: ["Integration Management", "Workflow Management", "Rich Analytics", "Permission Based Access"]
//     }
// ]}

// array={
//     [
//         {
//             id: 'Discrete',
//             title: "Discrete",
//             data: ["Automotive", "Industrial Manufacturing", "Aerospace & Defence", "Electric Vehicles"]
//         },
//         {
//             title: "Process",
//             data: ["Food and Beverages", "Cosmetics", "Pharmaceuticals", "Textile"]
//         }
//     ]
// }