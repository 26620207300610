import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.css";
import Navbar from "./Component/Header/NavBar";
//import Navbar from './Component/HeaderJs/Navbar';
import Footer from './Component/FooterJS/Footer';
import Home from './Component/Home';
import ScrollToTop from './ScrollToTop';
import RequirementManagement from "./components/Plm_one_Main_component/plm_one_Main_file";
import PartMaster from "./components/Plm_one_Main_component/plm_two_Main_file";
import Changemanagement from "./components/Plm_one_Main_component/plm_three_Main_file";
import BillMaterial from "./components/Plm_one_Main_component/plm_four_Main_file";
import FileManagement from "./components/Plm_one_Main_component/plm_five_Main_file";
import SupplierManagement from "./components/Plm_one_Main_component/plm_six_Main_file";
import QualityManagement from "./components/Plm_one_Main_component/plm_seven_Main_file";
import InventoryManagement from "./components/Plm_one_Main_component/plm_eight_Main_file";
import MBOM from "./components/Plm_one_Main_component/plm_nine_Main_file";

import IngredientManagement from "./components/plm_two_Main_component/plm_eleven_Main_file";
import FormulationManagement from "./components/plm_two_Main_component/plm_twelve_Main_file";
import TestTrials from "./components/plm_two_Main_component/plm_thirteen_Main_file";
import ArtworkLabelling from "./components/plm_two_Main_component/plm_fourteen_Main_file";

import IntegrationManagement from "./components/plm_three_Main_component/plm_threeone_Main_file";
import WorkflowManagement from "./components/plm_three_Main_component/plm_threetwo_Main_file";
import RichAnalytics from "./components/plm_three_Main_component/plm_threesqure_Main_file";
import PermissionBasedAccess from "./components/plm_three_Main_component/plm_threefour_Main_file";

import Industries from "./components/plm_four_Main_component/slider/slider";
import Automotive from "./components/plm_four_Main_component/plm_fourone_Main_file";
import IndustrialManufacturing from "./components/plm_four_Main_component/plm_fourtwo_Main_file";
import AerospaceDefence from "./components/plm_four_Main_component/plm_fourthree_Main_file";
import ElectricVehicles from "./components/plm_four_Main_component/plm_four2_Main_file";
import Cosmetics from "./components/plm_four_Main_component/Cosmetics";
import Foodbeverages from "./components/plm_four_Main_component/Food-beverages";
import Pharmaceuticals from "./components/plm_four_Main_component/Pharmaceuticals";
import Textile from "./components/plm_four_Main_component/Textile";


//import ContactUs_file from "./components/plm_four_Main_component/contactUs_file";
import ContactUs_file from "./Component/Home/Get-in-touch/getInTouch";
import BomConfiguration from "./components/Plm_one_Main_component/BomConfiguration";
import Mobileslider from "./Component/Home/Solutions/Mobileslider";

const App = () => {

  return (
    <div className="App">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          {/* Discrete PLM */}
          <Route
            path="/Requirement-Management"
            element={<RequirementManagement />}
          />
          <Route path="/Part-Master" element={<PartMaster />} />
          <Route path="/Change-management" element={<Changemanagement />} />
          <Route path="/Bill-Material" element={<BillMaterial />} />
          <Route path="/File-Management" element={<FileManagement />} />
          <Route path="/Supplier-Management" element={<SupplierManagement />} />
          <Route path="/Quality-Management" element={<QualityManagement />} />
          <Route
            path="/Inventory-Management"
            element={<InventoryManagement />}
          />
          <Route path="/MBOM" element={<MBOM />} />
          <Route path="/Bom-Configuration" element={<BomConfiguration />} />
          {/* PLM */}
          <Route
            path="/Ingredient-Management"
            element={<IngredientManagement />}
          />
          <Route
            path="/Formulation-Management"
            element={<FormulationManagement />}
          />
          <Route path="/Test-Trials" element={<TestTrials />} />
          <Route path="/Artwork-Labelling" element={<ArtworkLabelling />} />

          {/* Other */}
          <Route
            path="/Integration-Management"
            element={<IntegrationManagement />}
          />
          <Route path="/Workflow-Management" element={<WorkflowManagement />} />
          <Route path="/Rich-Analytics" element={<RichAnalytics />} />
          <Route path="/Permission-Based-Access" element={<PermissionBasedAccess />} />

          <Route path="/Industries" element={<Industries 
          
            />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Industrial-Manufacturing" element={<IndustrialManufacturing />} />
          <Route path="/Aerospace-Defence" element={<AerospaceDefence />} />
          <Route path="/Electric-Vehicles" element={<ElectricVehicles />} />
          <Route path="/Cosmetics" element={<Cosmetics />} />
          <Route path="/Food-beverages" element={<Foodbeverages />} />
          <Route path="/Pharmaceuticals" element={<Pharmaceuticals />} />
          <Route path="/Textile" element={<Textile />} />
          <Route path="/contact-us" element={<ContactUs_file />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
