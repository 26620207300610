import React from 'react'
import { Link } from 'react-router-dom'

const CompanySection = () => {
    return (
        <div className='CompanySection'>
            <div className='ServiceInner'>
                <h4>Company</h4>
                <ul>
                    <li><Link to='/Service'>Service</Link> </li>
                    <li><a href='/#Features'>Features</a></li>
                    <li><a href="https://blog.actionplm.com/" target="_blank">Blog</a> </li>
                    <li><Link to="/contact-us">Contact Us</Link> </li>
                </ul>
            </div>
        </div>
    )
}

export default CompanySection