import React from "react";

import img1 from "../image/PBA.svg";
import img2 from "../image/PBA_img.svg";
import imgurlid4 from "../image/PBA_IMG1.svg";
import imgurlEasy from "../image/PBv1.svg";
import imgurlsource from "../image/PBv2.svg";
import imgurlWorkflow from "../image/Pbv3.svg";

import Page_partplmone from "../Page_partplmone";
import Page_partplmtwo from "../Page_partplmtwo";
function plm_threefour_Main_file() {
  return (
    <div>
      <Page_partplmone
        title={
          <>
            <span className="boldSpanone">P</span>ermission &nbsp;{" "}
            <span className="boldSpanone">B</span>ased &nbsp;{" "}
            <span className="boldSpanone">A</span>ccess 
          
          </>
        }
        body={
          "Permission-based access is a security model that enables you to control and limit who can view and edit in the application for employees, stakeholders and vendors. In order to establish a business structure that is secure and well-managed, permission-based access and functional privileges are permitted to specific roles. Administrators set a role with restricted access and functionality for user profiles based on their roles in permission-based access."
        }
        imgurl ={img1}
        classtitle="title4"
        IDLE_IMG="Integration_Management1"
        imgurlid={imgurlid4}
        Id_IMG="Integration_Management"
       
      />

      <Page_partplmtwo
      imgurlone={imgurlEasy}
      imgtext1={"Reduce The Risk Of Data Breach"}
      imgurltwo={imgurlsource}
      imgtext2={"Increase Proficiency"}
      imgurlthree={imgurlWorkflow}
      imgtext3={"Increasing Administrative Transparency"}
      imgurl={img2}
      classnameicon="icon_PBA"
      classnamepara="Workflow_text"
      classnamepara2="soureimg_text"
      classnamepara1="Easyimg_text"
      section_text="section-text"
      secation_Part="secation-Part"
      classnameicon1="classname_icon"
      classnameicon2="icon_img"
      content_box="content_divs"
      image_text="image_boxs"
    />
   
    </div>
  );
}

export default plm_threefour_Main_file;