import React from 'react'
import { Container } from 'react-bootstrap';
import Logo from "../image/logoName.svg";

const LogoSection = () => {
    return (
        <div className='LogoSection'>
            <Container>
                <div className='FooterName'>
                    <img src={Logo} alt="Logo" />
                </div>
                <div className='tagLine'>
                    <p> A sustainable platform for your complete product journey.</p>
                    {/* <p className='InputTag'>Enter your email & get details of your query</p> */}
                </div>
                <div className='InputButton'>
                     <p className='InputTag'>Enter your email & get details of your query</p> 
                    <input />
                    <button>Send</button>
                </div>
            </Container>
        </div>
    )
}

export default LogoSection