import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "./home.css"
import AboutImg from "../image/Home_About.svg";

import salesforce from "../image/salesforce.svg";
import handshake from "../image/handshake.svg";

import Keybenefits from './Keybenefits/Keybenefits';
import WhatmakeUS from './WhatMake/whatmake';
import Solution from './Solutions/Solution';
import ActionPLM from './ForgePLM/ForgePLM';
import AOS from 'aos';
import "aos/dist/aos.css"
import homelogo from "../image/homelogo.svg"

import Svgline from "./svgline"

import PopupExample from './Pop-up/popUP';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import MobileSwiper from './MobileSwiper/MobileSwiper';

const Home = () => {

  const [keybenefits, setKeybenefits] = useState(0)



  const handleScroll = () => {

    if (window.scrollY >= 300 && window.screen.width >= 450) {
      if (window.scrollY >= 1200) {
        document.getElementById("PER100").classList.remove('Main_animat')
        // console.log(window.scrollY)
        setKeybenefits(document.getElementById("PER100").classList.add('Main_animation'))
      } else if (window.scrollY < 1900) {
        document.getElementById("PER100").classList.remove('Main_animation')
      }
    } else if (window.scrollY >= 300 && window.screen.width <= 430) {
       // console.log(window.scrollY)
      // document.getElementById("per50_mobile").classList.replace('percentage_imgbox_reverse','percentage_imgbox')
      // document.getElementById("per25_mobile").classList.replace('percentage_imgbox_reverse','percentage_imgbox')
      if (window.scrollY >= 1000) {
        document.getElementById("PER100").classList.remove('Main_animat')
        setKeybenefits(document.getElementById("PER100").classList.add('Main_animation'))
      } else if (window.scrollY >= 850) {
        document.getElementById("PER100").classList.remove('Main_animation')
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  AOS.init({
    offset: 200,
    duration: 600,
    easing: 'ease-in-sine',
    delay: 50,
    disable: 'mobile'
  });

  return (
    <div className='Home_main'>
      <div className='home_inner'>
        <div className='shapeBox'>
          <div className='shapeBox_inner'></div>
        </div>
        <Container className='home_section'>
          <h1>ActionPLM</h1>
          <p className='mt-4'>ActionPLM is the Industry's Most Advanced PLM platform built on Salesforce.com</p>
          <div className='Salesforce_imgs'>
            <Row className='imgGaping'>
              <Col md={4}>
                <img src={homelogo} alt='salesforce' width="60%" />
              </Col>
              <Col md={4}>
                <img src={handshake} alt='salesforce' width="100%" />
              </Col>
              <Col md={4} className="salesforceHome">
                <img src={salesforce} alt='salesforce' width="60%" />
              </Col>
            </Row>
          </div>
          <PopupExample />
        </Container>
        <div className='shapeBox shapeBox_right'>
          <div className='shapeBox_inner shapeBox_inner_right '></div>
        </div>
        <div className='shapeBox  shapeBox_right_mobile'>
          <div className='shapeBox_inner shapeBox_inner_right '></div>
        </div>
      </div>

      <div className='section_what' data-aos="fade-up">
        <div className='section_frist'>
          <div className="StyleFont" id='afterReload'>
            <p>What</p>
            <span>we do <div className='dottPoint'></div></span>
          </div>
          <div className='rectangleImg'>
            <div className='rectangle'></div>
          </div>
        </div>
        <div className='section_second'>
          <div className='section_secondInner'>
            <p>We wish to deliver a world class PLM application which helps our clients to grow and increase their productivity.<br /> <br />
              ActionPLM helps customers by enabling digital solutions, which leads to collaboration to improve the whole world,
              as well as empowering all sizes of industries to ease complexity and increase productivity to gain edge over.</p>
          </div>
          <div className='section_shapBox'>
            <div className='shapBox_inner'></div>
          </div>
        </div>
      </div>

      {/* Key benefits */}
      <section data-aos="fade-up" className='keybenfits_mobile'>
        <Keybenefits keybenefits={keybenefits} />
      </section>

      {/* What make us better */}
      <section data-aos="fade-up" className='marginTop_MB' id='Features'>
        <WhatmakeUS />
      </section>

      {/* About Section */}
      <section data-aos="fade-up" className='AboutSection'>
        <div className='About_Section'>
          <Container>
            <Row>
              <Col md={6}>
                <div className='AboutImg'>
                  <img src={AboutImg} width="100%" />
                </div>
              </Col>
              <Col className='col_homeAbout' md={6}>
                <h2>About <span>Us</span> </h2>
                <p>ActionPLM is a complete and adaptable platform that ties digital thread between people and proceedings
                  in order to promote innovation.</p>
                <p>ActionPLM allows enterprises to deliver successful products by providing a single, secure application that connects people,
                  systems, and processes.ActionPLM data management system and trackability assists organisations in reducing costs, shorten time to market,
                  and delivering better quality and innovative products.</p>
                <div className='section_shapBox'>
                  <div className='shapBox_inner'></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/*ActionPLM*/}

      <section className='ActionPLM_Home' data-aos="fade-up">
        <ActionPLM />
        <div className='boxshap'></div>
      </section>


      {/* Collaboration with Salesforce 

      <section className='Salesforce_Home' data-aos="fade-up">
        <Container>
          <p className='para_bulid'>
            <span>Collaboration</span> with <span>Salesforce</span></p>
          <p className='subbulid_text'>
            Salesforce is a cloud-based software firm trusted by over 150,000 customers.
          </p>
          <p className='subbulid_text'>
            The combination of Salesforce and ActionPLM assists enterprises in meeting their business goals.
          </p>
        </Container>
        <Container className='Salesforce_imgs'>
          <Row>
            <Col>
              <img src={salesforce} alt='salesforce' width="60%" />
            </Col>
            <Col>
              <img src={handshake} alt='salesforce' width="100%" />
            </Col>
            <Col>
              <img src={logo} alt='salesforce' width="70%" />
            </Col>
          </Row>
        </Container>
        <Container className='Salesforce_imgs_content'>
          <p>Features which easily defines the use of Salesforce :</p>
          <Row>
            <Col>
              <div className='flexSqureIcon'>
                <div><img src={SqureIcon} /></div> Customizable
              </div>
            </Col>
            <Col>
              <div className='flexSqureIcon'>
                <div><img src={SqureIcon} /></div> Reliable Security
              </div>
            </Col>
            <Col>
              <div className='flexSqureIcon'>
                <div><img src={SqureIcon} /></div>Easy Integration
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      */}

      {/* Slider Cards */}
      <section className='sliderHome_Section' data-aos="fade-up">
        <Solution />
        <div className='boxShap'>
          <div className='boxShapInner'> </div>
        </div>
      </section>

      {/* Industries */}

      <section className='IndustryMain' data-aos="fade-up">
        <Container className='Industries'>
          <h2 className='para_bulid'><span>Industries</span></h2>
          <p className='subbulid_text'>With ActionPLM, create a digital twin of your products and business.</p>
        </Container>
        <Container id='progressBar' >
          <Row className=''>
            <Col sm="4">
              <div className='discrete'>
                <p>Discrete</p>
              </div>
              <div className='content_inner'>
                <Link to="/Automotive">
                  <div className='contentflex contentflex1 d-flex justify-content-between'>
                    <div className='toppadding textShadow navBar_link'>Automotive</div>
                  </div>
                </Link>
                <Link to="/Industrial-Manufacturing">
                  <div className='contentflex IndManu d-flex justify-content-between'>
                    <div className='toppadding textShadow'>Industrial Manufacturing</div>
                  </div>
                </Link>

                <Link to="/Aerospace-Defence">
                  <div className='contentflex d-flex justify-content-between' id='Aerospace'>
                    <div className='toppadding textShadow'>Aerospace & Defence</div>
                  </div>
                </Link>
                <Link to="/Electric-Vehicles">
                  <div className='contentflex d-flex justify-content-between'>
                    <div className='toppadding textShadow'>Electric Vehicles</div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col>
              <div className='progressBarMain'>
                <div className='content_inner'>
                  <Svgline />
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className='process'><p>Process</p></div>
              <div className='content_inner'>
                <Link to="/Food-beverages">
                  <div className='contentflex borderShadow rotateImg d-flex justify-content-between'>
                    <div className='toppadding rotatetext'>Food and Beverages</div>
                  </div>
                </Link>

                <Link to="/Cosmetics">
                  <div className='contentflex rotateImg d-flex justify-content-between'>
                    <div className='toppadding rotatetext'>Cosmetics</div>
                  </div>
                </Link>

                <Link to="/Pharmaceuticals">
                  <div className='contentflex rotateImg d-flex justify-content-between'>
                    <div className='toppadding rotatetext'>Pharmaceuticals</div>
                  </div>
                </Link>
                <Link to="/Textile">
                  <div className='contentflex rotateImg d-flex justify-content-between'>
                    <div className='toppadding rotatetext'>Textile</div>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='mobileSwiper' style={{ height: "650px" }}>
        <MobileSwiper />
      </section>
    </div >
  )
}
export default Home