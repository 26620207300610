import React from "react";
import img1 from "../image/Group_Pc.svg";


import box from "../image/Req_one.svg";
import Req_two from "../image/Req_two.svg";
import Req_three from "../image/Req_three.svg";

import Group_one from "../image/Group_one.svg";
  
import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/Group_two.svg";
import Group_bell from "../image/Group_bell.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_one_Main_file() {
  const svgFrt = (
   <svg className="svgone" viewBox="0 0 949 1181" fill="none" xmlns="http://www.w3.org/2000/svg">
      
      <path
        fill="none"
        stroke="#5C90C4"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
         stroke-dasharray="8 8"
        className="path "
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        d="M236.877 64.9904C105.5 -91.4999 -116 77.4854 80.3772 236.49C134.759 280.522 324.935 589.778 584 295C798 51.5 1014.5 201.5 926.5 375.5C882.135 463.221 280.404 573.934 189.5 883.5C97 1198.5 539 1247 641.5 1098"
        stroke-width="4"
        stroke-dasharray="8 8"
      />
    </svg>

  );
  setTimeout(() => {
    document.querySelector(".Req_one").style.display = "block";
     },0);
  setTimeout(() => {
    document.querySelector(".Req_two").style.display = "block";
  }, 2000);

  setTimeout(() => {
    document.querySelector(".Req_three").style.display = "block";
  }, 2700);
  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">R</span>equirement{" "}
            <span className="boldSpan">M</span>anagement
          </>
        }
        body={
          "Industries are facing lots of complexity to manage their process because of legacy systems. With ActionPLM, you can manage all your requirements along with tracking and approval processes."
        }
        imgurl={img1}
        changemanagmentimg1="changemanagment_img"
      changemanagmentimg="change_managment_img"

      />

      <Page2
        classes={["Req_one", "Req_two", "Req_three"]}
        svgFrt={svgFrt}
        imgurl_one={box}
        imgurl_two={Req_two}
        imgurl_three={Req_three}
      />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        imgurl_plm_five={Group_bell}
        bottom_one_P={"Product Requirement Tracking"}
        bottom_two_P={"Pre - Planning Of Budgets"}
        bottom_three_P={"Automated Notification System"}
      />
    </div>
  );
}

export default plm_one_Main_file;
