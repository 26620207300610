import React from "react";
import img1 from "../image/WorkFlowManagement.svg";
import imgurlid2 from "../image/WorkFlow_Management_IMG1.svg";

import img2 from "../image/WorkFlowManagement_second.svg";
import imgurlEasy from "../image/WorkFlow_EasyMonitoring.svg";
import imgurlsource from "../image/WorkFlowEasyTraceability.svg";
import imgurlWorkflow from "../image/WorkFlowFlow_user.svg";
import Page_partplmone from "../Page_partplmone";
import Page_partplmtwo from "../Page_partplmtwo";
function plm_threetwo_Main_file() {
  return (
    <div>
      <Page_partplmone
        title={
          <>
            <span className="boldSpanone">W</span>orkFlow &nbsp;{" "}
            <span className="boldSpanone">M</span>anagement
          </>
        }
        body={
          "Customers can easily create, manage, and modify workflows with ActionPLM without using an external application. Implementing ActionPLM ensures that work is completed in a transparent, predetermined manner with structure and consistency, which is one of its key benefits."
        }
        imgurl={img1}
        imgurlid={imgurlid2}
        classtitle="title3"
        IDLE_IMG="Integration_Management1"
        /*imgurlid={imgurlid1}*/
        Id_IMG="Integration_Management"
        imgurl_img="Integrationimg"
       

      />
      <Page_partplmtwo
        imgurlone={imgurlEasy}
        imgtext1={"Easy Monitoring"}
        imgurltwo={imgurlsource}
        imgtext2={"Easy Traceability"}
        imgurlthree={imgurlWorkflow}
        imgtext3={"User-Defined Flow"}
        imgurl={img2}
        classnameicon="icon1"
        classnamepara="Usertitle"
        classnamepara1="imgtext"
        classnamepara2="classname_para"
        section_text="section-text"
        secation_Part="secation-Part"
        classnameicon1="img_Traceability"
        classnameicon2="img_flow"
        content_box="content_div"
        image_text="image_box"
      />
    </div>
  );
}

export default plm_threetwo_Main_file;
