import React from "react";

import "./Page_DiscreteIndustries2.css";
function Page_DiscreteIndustries2({
  title,
  body,
  imgurl,
  imgurlone,
  imgurltwo,
  imgurlthree,
  imgtext1,
  imgtext2,
  imgtext3,
  imgurlfour,
  imgtext4,
  colorname,
  idName,
  classpara,
  imgurl1,
  idpara,
  iD_name,
  id_para,
  idpara1,
  imgurlthree1,
  automotivebg_img,
  imgurl_img,
  Automotive_img,
  imgurl_Food,
  Food_Beverage,
  imgurlimg
}) {
  return (
    <div className="sectionplmoneDi" id={idName}>
      <div className="image-sectionplmone">
        <img className={Automotive_img} src={imgurl_img} alt="" />
<div className="automotive_imgs" src={imgurlimg} alt="" />
        <img className={automotivebg_img} src={imgurl} alt="" />
        
      
      </div>
      <div className="content-sectionplmoneDiv">
        <h1 className={colorname}> {title}</h1>

        <p>{body}</p>
        <div className="content-sectionplmtwoDi1">
          <div className="contentimgsDi1">
            <div className="imgIntegrationDi1">
  {/*  <img className={Food_Beverage} src={imgurl_Food} alt="" />*/}

              <img id={iD_name} src={imgurlone} alt="" />
              <p>{imgtext1}</p>
            </div>
            <div className="imgIntegrationDi1">
              {" "}
              <img id={classpara} src={imgurltwo} alt="" />
              <p>{imgtext2}</p>{" "}
            </div>
            {/*    <img  id={idpara1} src={imgurlthree1} alt="" />*/}
            <div className="imgIntegrationDi1">
              {" "}
              <img id={idpara} src={imgurlthree} alt="" />
              <p> {imgtext3}</p>
            </div>

            <div className="imgIntegrationDi1">
              {" "}
              <img id={id_para} src={imgurlfour} alt="" />
              <p> {imgtext4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page_DiscreteIndustries2;
