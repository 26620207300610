import React, { useRef } from 'react'
import { Button } from 'react-bootstrap';
import "./solutions.css";
import sliderIcon from "../../image/sliderIcon.svg";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Autoplay } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import "./pagation.css";

const Mobileslider = ({ slideBox, slidedown, slideRightTop, slideRightDown }) => {

    let btnRef = useRef();

    return (
        <div className='M_solution' id="mSolution">
            <Swiper
                slidesPerView={1}
                spaceBetween={270}
                pagination={true}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 30
                    }
                }}
                modules={[Mousewheel, Autoplay, Pagination]}
                className="mysliderMobile"
                id='mysliderMobile'
            >
                <SwiperSlide>
                    <div className='sliderCards_mobile'>
                        <div className='DiscreteIndustry'>
                            <p>Discrete Industry</p>
                            <div className='sliderData'>
                                <div className='slideImg'>
                                    <div className='slideImgflex'>
                                        <Button ref={btnRef} style={{ background: "none", border: "white" }} id="up" onClick={slidedown}>
                                            <div className='arrow-down'>
                                                <img src={sliderIcon} className="up-down" />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div className='MainSlider'>
                                    <div className='sliderInner' id='slide_mobile'>
                                        <Link to="/Requirement-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p>Requirement Management</p>
                                            </div>
                                        </Link>
                                        <Link to="/Part-Master">
                                            <div className='innerSliderBox border-gradient '>
                                                <p className='PartM'>Part Master</p>
                                            </div>
                                        </Link>
                                        <Link to="/Change-management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>Change Management</p>
                                            </div>
                                        </Link>
                                        <Link to="/MBOM">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='MBom'>Manufacturing Bill of Material (MBOM)</p>
                                            </div>
                                        </Link>
                                        <Link to="/Supplier-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p class="PartM">Supplier Management</p>
                                            </div>
                                        </Link>
                                        <Link to="/Bill-Material">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>Bill of Material</p>
                                            </div>
                                        </Link>
                                        <Link to="Quality-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>Quality Management</p>
                                            </div>
                                        </Link>
                                        <Link to="BOM-Configuration">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>BOM Configuration</p>
                                            </div>
                                        </Link>
                                        <Link to="Inventory-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p class="PartM">Inventory Management</p>
                                            </div>
                                        </Link>
                                        <Link to="File-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>File Management</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='slideImg'>
                                    <div className='slideImgflex'>
                                        <Button style={{ background: "none", border: "white" }} id="Down" onClick={slideBox}>
                                            <div className='arrow-up'>
                                                <img src={sliderIcon} alt="ArrowDown" className="up" />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='sliderCards_mobile'>
                        <div className='ProcessIndustry'>
                            <p>Process Industry</p>
                            <div className='sliderData'>
                                <div className='slideImg'>
                                    {/* <img src={sliderIcon} /> */}
                                    <div className='slideImgflex'>
                                        <Button style={{ background: "none", border: "white" }} id="upRight" onClick={slideRightDown}>
                                            <div className='arrow-down'>
                                                <img src={sliderIcon} alt="ArrowDown" className="up-down" />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div className='MainSlider'>
                                    <div className='sliderInner' id='slideRight_mobile'>
                                        <Link to="/Ingredient-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartMT'>
                                                    Ingredient Management
                                                </p>
                                            </div>
                                        </Link>
                                        <Link to="/Formulation-Management">
                                            <div className='innerSliderBox border-gradient '>
                                                <p>Formulation Management</p>
                                            </div>
                                        </Link>
                                        <Link to="/Test-Trials">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartMT'>Test & Trials</p>
                                            </div>
                                        </Link>
                                        <Link to="/Artwork-Labelling">
                                            <div className='innerSliderBox border-gradient'>
                                                <p className='PartM'>Artwork & Labelling</p>
                                            </div>
                                        </Link>
                                        {/* <Link to="/Workflow-Management">
                                            <div className='innerSliderBox border-gradient'>
                                                <p>Workflow Management</p>
                                            </div>
                                        </Link> */}
                                    </div>
                                </div>
                                <div className='slideImg'>
                                    <div className='slideImgflex'>
                                        <Button style={{ background: "none", border: "white" }} id="darkArrow" onClick={slideRightTop}>
                                            <div className='arrow-up'>
                                                <img src={sliderIcon} alt="ArrowDown" className="up" />
                                            </div>
                                        </Button>
                                    </div>
                                    {/* <img src={replace ? sliderIcon : ArrowDown} id="darkArrow" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>

    )
}

export default Mobileslider