import React from "react";
import img1 from "../image/file_managment_img.svg";

import box from "../image/FileManagement_img.svg";

import Group_one from "../image/file_managment_Document_control.svg";

import Vector_one from "../image/Vector_one.svg";
import Group_two from "../image/file_managment_centralised_Document.svg";

import Page_one from "../Page_one";
import Page2 from "../Page2";
import Pagethree from "../Pagethree";
function plm_four_Main_file() {
  const svgFrt = (
    <svg
      className="svgfive"
      viewBox="0 0 1086 700"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke="#5C90C4"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        stroke-width="4"
        className="path"
        d="M458.999 157.233C355.999 -73.2683 -187.074 22.3507 72.4559 253.242C144.956 317.742 460.623 239.8 687.5 86.4996C1014.71 -134.596 1194.5 137.5 1009 253.242C870.165 339.868 658.88 260.508 391.956 441.742C80.456 653.242 542 807.5 998.5 598"
      />
      <path
        className="dashed"
        fill="none"
        stroke="white"
        stroke-linejoin="round"
        stroke-miterlimit="40"
        stroke-width="4"
        d="M458.999 157.233C355.999 -73.2683 -187.074 22.3507 72.4559 253.242C144.956 317.742 460.623 239.8 687.5 86.4996C1014.71 -134.596 1194.5 137.5 1009 253.242C870.165 339.868 658.88 260.508 391.956 441.742C80.456 653.242 542 807.5 998.5 598"
      />
    </svg>
  );
  setTimeout(() => {
    document.querySelector(".Page5_p1").style.display = "block";
  }, 0);

  return (
    <div>
      <Page_one
        title={
          <>
            <span className="boldSpan">F</span>ile{" "}
            <span className="boldSpan">M</span>anagement{" "}
          </>
        }
        body={
          "Throughout the product journey, a number of documents are generated that contain crucial information that must be linked and become a part of the product record. ActionPLM file management offers a secure version and change control for files in a variety of formats that are easily accessible to users."
        }
        imgurl={img1}
        changemanagmentimg="imgchangemag1"
        changemanagmentimg1="changemanagment_img"

      />

      <Page2 classes={["Page5_p1"]} svgFrt={svgFrt} imgurl_one={box} />
      <Pagethree
        imgurl_plm_one={Group_one}
        imgurl_plm_two={Vector_one}
        imgurl_plm_three={Group_two}
        fved="hdevector"
        bottom_one_P={"Document Versioning Control"}
        bottom_two_P={"Centralised Document"}
        fved1="Document_img"
        fved_group="IMG_file"
      />
    </div>
  );
}

export default plm_four_Main_file;
