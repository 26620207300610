import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './styles.css';
import { Pagination, Navigation } from "swiper";
import "./Page_partplmtwo.css";
const Page_partplmtwo =({
  title,
  body,
  imgurl,
  imgurlone,
  imgurltwo,
  imgurlthree,
  imgtext1,
  imgtext2 ,
  imgtext3,
  classnameicon,
  classnamepara,
  classnamepara1,
  classnamepara2,
  classnameicon1,
  classnameicon2,
  section_text,
  secation_Part,
  content_box,
  image_text
  
}) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  let appendNumber = 4;
  let prependNumber = 1;

  const prepend2 = () => {
    swiperRef.prependSlide([
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>",
    ]);
  };

  const prepend = () => {
    swiperRef.prependSlide(
      '<div class="swiper-slide">Slide ' + --prependNumber + "</div>"
    );
  };

  const append = () => {
    swiperRef.appendSlide(
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>"
    );
  };

  const append2 = () => {
    swiperRef.appendSlide([
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
      '<div class="swiper-slide">Slide ' + ++appendNumber + "</div>",
    ]);
  };


  return (
    <div>
    <div id={secation_Part} className="sectionplmtwo">
      <div className="content-sectionplmtwo">
        <div className="contentimgs">
          <div   className="imgIntegration">
            <img  className={classnameicon} id="imgIntegrations" src={imgurlone} alt="" />
            <p className={classnamepara1}>{imgtext1}
            </p>
          </div>
          <div  className="imgIntegration">
            {" "}
            <img className={classnameicon1} src={imgurltwo} alt="" />
            <p className={classnamepara2}>{imgtext2}</p>{" "}
          </div>
          <div className="imgIntegration">
            {" "}
            <img className={classnameicon2}  src={imgurlthree} alt="" />
          <p className={classnamepara}> {imgtext3}</p> 
          </div>
        </div>
 <p>{body}</p>
      </div>
      <div className="image-sectionplmtwo" >
        <img src={imgurl} alt="" />
      </div>
    </div>
    <div className="contentimgs">
    <div id={section_text} className="sectionplmtwo">
      <div className="content-sectionplmtwo" id={content_box}>
      <div className="image-sectionplmtwo" id={image_text}>
      <img src={imgurl} alt="" />
    </div>
      <div className="">
  <div className="">
        <Swiper 
        onSwiper={setSwiperRef}
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={100}       
        className="mySwiper" >
     <SwiperSlide>
          <div   className="imgIntegration">
 <img  className={classnameicon} id="imgIntegrations" src={imgurlone} alt="" />
            <p className={classnamepara1}>{imgtext1}
            </p>
          </div>
          </SwiperSlide>
         <SwiperSlide>
          <div  className="imgIntegration">
            {" "}
            <img className={classnameicon1} src={imgurltwo} alt="" />
            <p className={classnamepara2}>{imgtext2}</p>{" "}
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="imgIntegration">
            {" "}
            <img className={classnameicon2}  src={imgurlthree} alt="" />
          <p className={classnamepara}> {imgtext3}</p> 
          </div>
          </SwiperSlide>
         
          </Swiper>
          </div>
         </div>
          </div>
            </div>
          </div>
        </div>
        
  );
}
export default Page_partplmtwo;
