import React from "react";
import "../components/Page2.css";
function Page2({ imgurl_one, classes, svgFrt, imgurl_two, imgurl_three }) {
  return (
    <div className="Req">
      {svgFrt}
      <div>
        <img id="box" src={imgurl_one} className={classes[0]} alt="" />
        <img src={imgurl_two} alt="" className={classes[1]} />
        <img src={imgurl_three} alt="" className={classes[2]} />
      </div>
    </div>
  );
}
export default Page2;
