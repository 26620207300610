import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../slider/s.css";
import imgurlEasy from "../../image/SteeringWheel.svg";
import imgurlsource from "../../image/SteeringWheel.svg";
import imgurlWorkflow from "../../image/SteeringWheel.svg";
import imgslider from "../../image/Arrow.svg";
import img2 from "../../image/DiscreteIndustries_img.svg";
import imgurlEasy1 from "../../image/IndustrialManufacturing_img.svg";
import imgurlsource1 from "../../image/IndustrialManufacturing_img.svg";
import imgurlWorkflow1 from "../../image/IndustrialManufacturing_img.svg";
import imgurlWorkflowM1 from "../../image/IndustrialManufacturing_img.svg";
import img3 from "../../image/IndustrialManufacturing.svg";
import imgurlEasy2 from "../../image/Aerospace&Defence.svg";
import imgurlsource2 from "../../image/Aerospace&Defence.svg";
import imgurlWorkflow2 from "../../image/Aerospace&Defence.svg";
import imgurlWorkflowM2 from "../../image/Aerospace&Defence.svg";
import img4 from "../../image/Aerospace&Defence_img.svg";
import imgurlEasy3 from "../../image/ElectricVehicles1.svg";
import imgurlsource3 from "../../image/ElectricVehicles1.svg";
import imgurlWorkflow3 from "../../image/ElectricVehicles1.svg";
import img5 from "../../image/Electric-Vehicles_img.svg";
import imgurlEasyPD1 from "../../image/Food&BeveragePi.svg";
import imgurlsourcePD1 from "../../image/Food&BeveragePi.svg";
import imgurlWorkflowPD1 from "../../image/Food&BeveragePi.svg";
import img6 from "../../image/Food&Beverage.svg";
import imgbg from "../../image/Automotive_img.svg";
import imgurlEasyPD2 from "../../image/Cosmetics_img.svg";
import imgurlsourcePD2 from "../../image/Cosmetics_img.svg";
import imgurlWorkflowPD2 from "../../image/Cosmetics_img.svg";
import img7 from "../../image/Cosmetics.svg";
import imgurlEasyPD3 from "../../image/Pharmaceuticals.svg";
import imgurlsourcePD3 from "../../image/Pharmaceuticals.svg";
import imgurlWorkflowPD3 from "../../image/Pharmaceuticals.svg";
import img8 from "../../image/Pharmaceuticals_img.svg";
import imgurlEasyPD4 from "../../image/Textile_img.svg";
import imgurlsourcePD4 from "../../image/Textile_img.svg";
import imgurlWorkflowPD4 from "../../image/Textile_img.svg";
import imgurlWork1flowPD4 from "../../image/Textile_img.svg";
import img9 from "../../image/Textile.svg";
import img_Electric from "../../image/Electric_Vehicles_img.svg";
import img_url from "../../image/Industrial_Manufacturing_img.svg";
import img_Defence from "../../image/Aerospace_Defence_img.svg";
import imgurlFood from "../../image/Food&Beverage.svg";
import img_Automotive from "../../image/automotive_bg.svg";
import Page_Discretelndustries1 from "../../Page_DiscreteIndustries1";
import Page_Discretelndustries2 from "../../Page_DiscreteIndustries2";
import Page_Discretelndustries4 from "../../Page_Discretelndustries4";
import sliderIcon from "../../../Component/image/mobileImages/brownArrow.svg"
import automotive from "../../../Component/image/mobileImages/automotive.svg"
import Industrial from "../../../Component/image/mobileImages/IndustrialManufu.svg"
import AerospaceDefence from "../../../Component/image/mobileImages/AerospaceDefence.svg"
import ElectricVehicles from "../../../Component/image/mobileImages/ElectricVehicles.svg"
import whiteArro from "../../../Component/image/mobileImages/whiteArro.svg"
import FoodBeverage from "../../../Component/image/mobileImages/FoodBeverage.svg"
import Cosmetics from "../../../Component/image/mobileImages/Cosmetics.svg"
import Pharmaceuticals from "../../../Component/image/mobileImages/Pharmaceuticals.svg"

const Slider = () => {

  const [screenwidth, setScreenWitdh] = useState(true)

  let x = 0;
  const slideBoxed = (e) => {
    if (e.target.id === "induAutomative") {
      if (x > "-420") {
        x = x - 70;
        document.getElementById("slideIndustrie").style.top = x + "%";
        //console.log(x)
      } else if (x > "-420" && window.screen.width <= 320) {
        x = x - 70;
        document.getElementById("slideIndustrie").style.top = x + "%";
        console.log(x)
      }
    } else if (e.target.id === "industriImgIcon") {
      if (x > "-420") {
        x = x - 70;
        document.getElementById("slideIndustries").style.top = x + "%";
        //console.log(x)
      }
    }
  }
  const slidedown = (e) => {
    if (e.target.id === "UpImgTop") {
      if (x < 0) {
        x = x + 105;
        document.getElementById("slideIndustrie").style.top = x + "%";
      }
    } else if (e.target.id === "industriUp") {
      if (x < 0) {
        x = x + 70;
        document.getElementById("slideIndustries").style.top = x + "%";
      }
    }

  }


  useEffect(() => {
    if (window.screen.width <= 430) {
      setScreenWitdh(false)
    }
  }, [])

  return (
    <>
      <Page_Discretelndustries1
        title={
          <>
            <span className="boldSpan">D</span>iscrete{" "}
            <span className="boldSpan"> I</span>ndustries
          </>
        }
        body={
          "PLM solution for discrete manufacturers to improve productivity and customer experience with industry-specific capabilities."
        }
      />
      <div className="SwiperJs" id="swperdd">
        {
          screenwidth ? <Swiper navigation={true} modules={[Navigation]} className="mySwiper" >
            <SwiperSlide>
              <Page_Discretelndustries2
                title={
                  <>
                    <span className="boldSpan">A</span>utomotive{" "}
                  </>
                }
                imgurlone={imgurlEasy}
                imgtext1={
                  "Traditional processes and systems are unable to fulfill changes in business requirements, to stay competitive and profitable."
                }
                imgurltwo={imgurlsource}
                imgtext2={
                  "Product lifecycle management (PLM) software provides a framework for the automobile industry to integrate product design, life-cycle planning, assembly, testing, and maintenance. ActionPLM combines all PLM functions into a single package. "
                }
                imgurlthree={imgurlWorkflow}
                imgtext3={
                  "Automotive firms use ActionPLM to integrate worldwide product development across all disciplines, manage complex BoMs, and support global supply and production chains."
                }


                imgurl_img={imgbg}
                imgurl={img2}
                colorname="black"
                idName="Automotive"
                classpara="classpara1"
                idpara="idparaplm"
                imgurlthree1={imgslider}
                Automotive_img="automotive_bg_img"
                automotivebg_img="automotive_img"
                imgurlimg={img_Automotive}
                automotive_img="automotive"
                automotive_imgs="img"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Page_Discretelndustries2
                title={
                  <>
                    <span className="boldSpan">I</span>ndustrial{" "}
                    &nbsp; <span className="boldSpan">M</span>anufacturing
                  </>

                }
                imgurlone={imgurlEasy1}
                imgtext1={"ActionPLM helps the industry to enhance the speed and quality of new product launches and engineering modification orders."}
                imgurltwo={imgurlsource1}
                imgtext2={"Manufacturers are transforming their ways in which they design, manufacture, and maintain the products that they sell to customers."}
                imgurlthree={imgurlWorkflow1}
                imgtext3={"Every organisation made an effort to stay ahead of the competition by incorporating new technology that met their requirements."}
                imgurlfour={imgurlWorkflowM1}
                imgtext4={"Many successful businesses do this by using digital technologies and changing to a customer-centric business strategy."}
                imgurl_img={img_url}
                imgurl={img3}
                colorname="purpal"
                idName="Industrial-Manufacturing"
                classpara="img_icon"
                idpara="icon_img"
                Automotive_img="Manufacturing"
                /*D_name="Industrial_Manufacturing"*/

                automotivebg_img="Industrial_Manufacturing"
              /></SwiperSlide>
            <SwiperSlide>
              <Page_Discretelndustries2
                title={
                  <>
                    <span className="boldSpan">A</span>erospace{" "}
                    &nbsp; <span className="boldSpan">& </span> &nbsp; &nbsp; {" "}
                    <span className="boldSpan">D</span>efence{" "}
                  </>
                }
                imgurlone={imgurlEasy2}
                imgtext1={
                  "ActionPLM platform streamlines product design,manufacturing and maintenance for continuous information and communication exchange."
                }
                imgurltwo={imgurlsource2}
                imgtext2={
                  "We help you in connecting your systems together so that you have the connectivity and accessbility required."
                }
                imgurlthree={imgurlWorkflow2}
                imgtext3={
                  "Organisations in the aerospace and defence industries are developing high-performance platforms and systems to manage new technology, propulsion engines and other hardware tech. "
                }
                imgurlfour={imgurlWorkflowM2}
                imgtext4={
                  "ActionPLM helps you in improving your infrastructure and security with your state-of-the-art platform capabilities."
                }
                imgurl_img={img_Defence}


                imgurl={img4}
                colorname="brown"
                idName="Aerospace-Defence"
                iD_name="id_name"
                classpara="class_para"
                idpara="id_icon"
                id_para="id_paraicon"
                Automotive_img="automotive_bg_img1"
                automotivebg_img="automotive_img"

              />
            </SwiperSlide>
            <SwiperSlide>
              <Page_Discretelndustries2
                title={
                  <>
                    <span className="boldSpan">E</span>lectric{" "}
                    &nbsp; <span className="boldSpan">V</span>ehicles
                  </>
                }
                imgurlone={imgurlEasy3}
                imgtext1={
                  "Electric vehicles are the constant revolution in the automotive industry and they are one of the key sectors of innovation today."
                }
                imgurltwo={imgurlsource3}
                imgtext2={
                  "ActionPLM adds significant value to this industry by improving mapping, streamlining the product design process, and providing value to businesses."
                }
                imgurlthree={imgurlWorkflow3}
                imgtext3={
                  "It enables the EV sector to benefit from current industry advancements, manage partnerships and suppliers, decrease product development timelines and bring new ideas to market quickly and efficiently with plentiful resources."
                }
                imgurl={img5}
                colorname="green"
                idName="Electric-Vehicles"
                idpara="idparas"
                iD_name="id_names"
                classpara="classpara_icon"
                imgurl_img={img_Electric}
                Automotive_img="Electric"
                automotivebg_img="Electric_vehicles"
              />

            </SwiperSlide>

          </Swiper> :
            <div className='sliderData'>
              <div className='MainSlider'>
                <div className='industriImgTop'>
                  <img src={sliderIcon} className="up-down" id="UpImgTop" onClick={slidedown} />
                </div>
                <div className='sliderInner' id='slideIndustrie'>

                  <div className="w-100 h-100 backgroundImgData">
                    <img src={automotive} alt="data" width="100%" height="100%" />

                    {/* <Page_Discretelndustries2
                      title={
                        <>
                          <span className="boldSpan">A</span>utomotive{" "}
                        </>
                      }
                      imgurlone={imgurlEasy}
                      imgtext1={
                        "Traditional processes and systems are unable to fulfill changes in business requirements, to stay competitive and profitable."
                      }
                      imgurltwo={imgurlsource}
                      imgtext2={
                        "Product lifecycle management (PLM) software provides a framework for the automobile industry to integrate product design, life-cycle planning, assembly, testing, and maintenance. ActionPLM combines all PLM functions into a single package. "
                      }
                      imgurlthree={imgurlWorkflow}
                      imgtext3={
                        "Automotive firms use ActionPLM to integrate worldwide product development across all disciplines, manage complex BoMs, and support global supply and production chains."
                      }
                      imgurl_img={imgbg}
                      imgurl={img2}
                      colorname="black"
                      classpara="classpara1"
                      idName="Automotive"
                      idpara="idparaplm"
                      imgurlthree1={imgslider}
                      Automotive_img="automotive_bg_img"
                      automotivebg_img="automotive_img"
                      imgurlimg={img_Automotive}
                      automotive_img="automotive"
                      automotive_imgs="img"
                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <img src={Industrial} alt="data" width="100%" height="100%" />

                    {/* <Page_Discretelndustries2
                      title={
                        <>
                          <span className="boldSpan">I</span>ndustrial{" "}
                          &nbsp; <span className="boldSpan">M</span>anufacturing
                        </>
                      }
                      imgurlone={imgurlEasy1}
                      imgtext1={"ActionPLM helps the industry to enhance the speed and quality of new product launches and engineering modification orders."}
                      imgurltwo={imgurlsource1}
                      imgtext2={"Manufacturers are transforming their ways in which they design, manufacture, and maintain the products that they sell to customers."}
                      imgurlthree={imgurlWorkflow1}
                      imgtext3={"Every organisation made an effort to stay ahead of the competition by incorporating new technology that met their requirements."}
                      imgurlfour={imgurlWorkflowM1}
                      imgtext4={"Many successful businesses do this by using digital technologies and changing to a customer-centric business strategy."}
                      imgurl_img={img_url}
                      imgurl={img3}
                      colorname="purpal"
                      idName="Industrial-Manufacturing"
                      classpara="img_icon"
                      idpara="icon_img"
                      Automotive_img="Manufacturing"
                      D_name="Industrial_Manufacturing"
                      automotivebg_img="Industrial_Manufacturing"
                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <img src={AerospaceDefence} alt="data" width="100%" height="100%" />

                    {/* <Page_Discretelndustries2
                      title={
                        <>
                          <span className="boldSpan">A</span>erospace{" "}
                          &nbsp; <span className="boldSpan">& </span> &nbsp; &nbsp; {" "}
                          <span className="boldSpan">D</span>efence{" "}
                        </>
                      }
                      imgurlone={imgurlEasy2}
                      imgtext1={
                        "ActionPLM platform streamlines product design,manufacturing and maintenance for continuous information and communication exchange."
                      }
                      imgurltwo={imgurlsource2}
                      imgtext2={
                        "We help you in connecting your systems together so that you have the connectivity and accessbility required."
                      }
                      imgurlthree={imgurlWorkflow2}
                      imgtext3={
                        "Organisations in the aerospace and defence industries are developing high-performance platforms and systems to manage new technology, propulsion engines and other hardware tech. "
                      }
                      imgurlfour={imgurlWorkflowM2}
                      imgtext4={
                        "ActionPLM helps you in improving your infrastructure and security with your state-of-the-art platform capabilities."
                      }
                      imgurl_img={img_Defence}


                      imgurl={img4}
                      colorname="brown"
                      idName="Aerospace-Defence"
                      iD_name="id_name"
                      classpara="class_para"
                      idpara="id_icon"
                      id_para="id_paraicon"
                      Automotive_img="automotive_bg_img1"
                      automotivebg_img="automotive_img"

                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <img src={ElectricVehicles} alt="data" width="100%" height="100%" />

                    {/* <Page_Discretelndustries2
                      title={
                        <>
                          <span className="boldSpan">E</span>lectric{" "}
                          &nbsp; <span className="boldSpan">V</span>ehicles
                        </>
                      }
                      imgurlone={imgurlEasy3}
                      imgtext1={
                        "Electric vehicles are the constant revolution in the automotive industry and they are one of the key sectors of innovation today."
                      }
                      imgurltwo={imgurlsource3}
                      imgtext2={
                        "ActionPLM adds significant value to this industry by improving mapping, streamlining the product design process, and providing value to businesses."
                      }
                      imgurlthree={imgurlWorkflow3}
                      imgtext3={
                        "It enables the EV sector to benefit from current industry advancements, manage partnerships and suppliers, decrease product development timelines and bring new ideas to market quickly and efficiently with plentiful resources."
                      }
                      imgurl={img5}
                      colorname="green"
                      idName="Electric-Vehicles"
                      idpara="idparas"
                      iD_name="id_names"
                      classpara="classpara_icon"
                      imgurl_img={img_Electric}
                      Automotive_img="Electric"
                      automotivebg_img="Electric_vehicles"
                    /> */}
                  </div>
                </div>
                <div className='industriImg'>
                  <img src={whiteArro} alt="ArrowDown" className="up" id="induAutomative" onClick={slideBoxed} />
                </div>
              </div>
            </div>
        }
      </div>

      <Page_Discretelndustries1
        title={
          <>
            <span className="boldSpan">P</span>rocess{" "}
            <span className="boldSpan">I</span>ndustries
          </>
        }
        body={
          "Simplified solution for process industry to enhance product development through quality, product, inventory and supplier management."
        }
      />
      <div className="SwiperJs" id="swperprocess">
        {
          screenwidth ?
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper" >
              <SwiperSlide>
                <Page_Discretelndustries4
                  imgurl1={img6}
                  title={
                    <>
                      <span className="boldSpan">F</span>ood{" "}
                      <span className="boldSpan">&</span>
                      <span className="boldSpan"> B</span>everage
                    </>
                  }
                  imgurlonepD={imgurlEasyPD1}
                  imgurl_Food={imgurlFood}
                  Food_Beverage=" FoodBeverage_img"
                  iD_name="Food_Beverage"
                  imgtext1={
                    "The food and beverage industry manages enormous amounts of data related to ingredients, formulas, test and trials, as well as intense competition and other challenges during the process."
                  }
                  imgurltwopD={imgurlsourcePD1}
                  imgtext2={
                    "With ActionPLM, you can manage supplier collaboration and communication, speed up the R & D process, ensure compliance and other data from different departments. "
                  }
                  imgurlthreepD={imgurlWorkflowPD1}
                  imgtext3={
                    "ActionPLM makes it easier to produce new products by solving these issues in a timely and efficient manner on a daily basis."
                  }
                  idName="Food-beverages"

                />
              </SwiperSlide>
              <SwiperSlide>

                <Page_Discretelndustries4
                  imgurl1={img7}
                  title={
                    <>
                      <span className="boldSpan">C</span>osmetics{" "}

                    </>

                  }

                  imgurlonepD={imgurlEasyPD2}
                  imgtext1={"The cosmetics sector is very competitive due to shelf positioning and brand familiarity, which have a considerable impact on consumers' rapid selections. "}
                  imgurltwopD={imgurlsourcePD2}
                  imgtext2={"Manufacturers face a problem as consumers demand for environment friendly product packaging, material handling and regulatory ingredient management rise."}
                  imgurlthreepD={imgurlWorkflowPD2}
                  imgtext3={"ActionPLM helps the cosmetics sector with operations and New Product Development initiatives (N.P.D.). It also manages product quality, traceability and regulatory complexity in a global environment without compromising product release dates."}
                  colortitle="pink"
                  idName="Cosmetics"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Page_Discretelndustries4
                  imgurl1={img8}
                  title={
                    <>
                      <span className="boldSpan">P</span>harmaceuticals{" "}
                    </>
                  }
                  imgurlonepD={imgurlEasyPD3}
                  imgtext1={
                    "In the pharmaceutical industry, you are continually challenged to handle products, designs and associated documents and comply with regulatory standards specified by authorities while inventing new items."
                  }
                  imgurltwopD={imgurlsourcePD3}
                  imgtext2={
                    "ActionPLM has a product-centric approach, delivering PLM capabilities and support via automated best practices and workflow, which helps in increasing productivity, reliability and the ability to build new products more efficiently."
                  }

                  colortitle="darkgreen"
                  idName="Pharmaceuticals"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Page_Discretelndustries4
                  imgurl1={img9}
                  title={
                    <>
                      <span className="boldSpan">T</span>extile{" "}
                    </>
                  }
                  imgurlonepD={imgurlEasyPD4}
                  imgtext1={
                    "The fashion and apparel industry is competitive and dynamic, so speed to market is critical. To keep you one step ahead of the competition, you need a solution that can provide visibility throughout the supply chain from beginning to end. "
                  }
                  imgurltwopD={imgurlsourcePD4}
                  imgtext2={
                    "In order to compete in this dynamic and highly competitive industry retailers, manufacturers and brands must develop more quickly and provide products at competitive prices and build brand equity. "
                  }
                  imgurlthreepD={imgurlWorkflowPD4}
                  imgtext3={
                    "Product life cycle management (PLM) in the textile sector entails controlling the entire process from conception to completion."
                  }
                  imgurlfourpD={imgurlWork1flowPD4}
                  imgtext4={
                    "ActionPLM facilitates the textile industry's operations management and associations of its workforce on a single platform."
                  }
                  colortitle="darkblue"
                  idName="Textile"
                />
              </SwiperSlide>
            </Swiper> :
            <div className='sliderData'>
              <div className='industriImgIcon'>
                <img src={sliderIcon} className="up-down" id="industriUp" onClick={slidedown} />
              </div>
              <div className='MainSlider'>
                <div className='sliderInner' id='slideIndustries'>
                  <div className="w-100 h-100">
                    <img src={FoodBeverage} alt="data" width="100%" height="100%" />
                    {/* <Page_Discretelndustries4
                      imgurl1={img6}
                      title={
                        <>
                          <span className="boldSpan">F</span>ood{" "}
                          <span className="boldSpan">&</span>
                          <span className="boldSpan"> B</span>everage
                        </>
                      }
                      imgurlonepD={imgurlEasyPD1}
                      imgurl_Food={imgurlFood}
                      Food_Beverage=" FoodBeverage_img"
                      iD_name="Food_Beverage"
                      imgtext1={
                        "The food and beverage industry manages enormous amounts of data related to ingredients, formulas, test and trials, as well as intense competition and other challenges during the process."
                      }
                      imgurltwopD={imgurlsourcePD1}
                      imgtext2={
                        "With ActionPLM, you can manage supplier collaboration and communication, speed up the R & D process, ensure compliance and other data from different departments. "
                      }
                      imgurlthreepD={imgurlWorkflowPD1}
                      imgtext3={
                        "ActionPLM makes it easier to produce new products by solving these issues in a timely and efficient manner on a daily basis."
                      }
                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <img src={Cosmetics} alt='data' width="100%" height="100%" />

                    {/* <Page_Discretelndustries4
                      imgurl1={img7}
                      title={
                        <>
                          <span className="boldSpan">C</span>osmetics{" "}
                        </>

                      }
                      imgurlonepD={imgurlEasyPD2}
                      imgtext1={"The cosmetics sector is very competitive due to shelf positioning and brand familiarity, which have a considerable impact on consumers' rapid selections. "}
                      imgurltwopD={imgurlsourcePD2}
                      imgtext2={"Manufacturers face a problem as consumers demand for environment friendly product packaging, material handling and regulatory ingredient management rise."}
                      imgurlthreepD={imgurlWorkflowPD2}
                      imgtext3={"ActionPLM helps the cosmetics sector with operations and New Product Development initiatives (N.P.D.). It also manages product quality, traceability and regulatory complexity in a global environment without compromising product release dates."}
                      colortitle="pink"
                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <img src={Pharmaceuticals} alt='data' width="100%" height="100%" />
                    {/* <Page_Discretelndustries4
                      imgurl1={img8}
                      title={
                        <>
                          <span className="boldSpan">P</span>harmaceuticals{" "}
                        </>
                      }
                      imgurlonepD={imgurlEasyPD3}
                      imgtext1={
                        "In the pharmaceutical industry, you are continually challenged to handle products, designs and associated documents and comply with regulatory standards specified by authorities while inventing new items."
                      }
                      imgurltwopD={imgurlsourcePD3}
                      imgtext2={
                        "ActionPLM has a product-centric approach, delivering PLM capabilities and support via automated best practices and workflow, which helps in increasing productivity, reliability and the ability to build new products more efficiently."
                      }
                      colortitle="darkgreen"
                    /> */}
                  </div>
                  <div className="w-100 h-100">
                    <Page_Discretelndustries4
                      imgurl1={img9}
                      title={
                        <>
                          <span className="boldSpan">T</span>extile{" "}
                        </>
                      }
                      imgurlonepD={imgurlEasyPD4}
                      imgtext1={
                        "The fashion and apparel industry is competitive and dynamic, so speed to market is critical. To keep you one step ahead of the competition, you need a solution that can provide visibility throughout the supply chain from beginning to end. "
                      }
                      imgurltwopD={imgurlsourcePD4}
                      imgtext2={
                        "In order to compete in this dynamic and highly competitive industry retailers, manufacturers and brands must develop more quickly and provide products at competitive prices and build brand equity. "
                      }
                      imgurlthreepD={imgurlWorkflowPD4}
                      imgtext3={
                        "Product life cycle management (PLM) in the textile sector entails controlling the entire process from conception to completion."
                      }
                      imgurlfourpD={imgurlWork1flowPD4}
                      imgtext4={
                        "ActionPLM facilitates the textile industry's operations management and associations of its workforce on a single platform."
                      }
                      colortitle="darkblue"
                    />
                  </div>
                </div>
              </div>
              <div className='industriImgIcon'>
                <img src={whiteArro} alt="ArrowDown" className="up" id="industriImgIcon" onClick={slideBoxed} />
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default Slider;
