import React, { useEffect } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import DiscreteIndustry from "../../image/DiscreteIndustry.svg";
import ProcessIndustry from "../../image/ProcessIndustry.svg";
import industrieImg from "../../image/industrieImg.svg"
import ion_shapesblue from "../../image/ion_shapesblue.svg";
import ion_shapespink from "../../image/ion_shapespink.svg";
import Accordion from 'react-bootstrap/Accordion';
import "./forgeplm.css"

const ActionPLM = () => {

    return (
        <Container>
            <p className='para_bulid'>Build better with  <span>ActionPLM</span></p>
            <p className='subbulid_text forPC'>ActionPLM delivers a better product to our clients with our unique ideas and implementation.</p>
            <div className='ActionPLM'>
                <div className='imgStyle'>
                    <div className='imgCricle'>
                        <div className='innerStyle'>
                            <img src={industrieImg} alt="industry" />
                        </div>
                    </div>
                </div>
                <div className='ActionPLM_inner'>
                    <div className='Plm_Inner'>
                        <div className='leftIndustry'>
                            <p id='DiscreteIndustry'>Discrete Industry</p>
                            <img src={DiscreteIndustry} />
                        </div>
                        <div className='RightIndustry'>
                            <p id='ProcessIndustry'>Process Industry</p>
                            <img src={ProcessIndustry} />
                        </div>
                    </div>
                    <div className='ActionPLM_Home_content'>
                        <div className='content_inner'>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div>
                                        <img src={ion_shapesblue} alt='icon' />
                                        <div>The discrete industry refers to the production of entire things that can be easily handled, numbered,or viewed.</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div>
                                        <img src={ion_shapesblue} alt='icon' />
                                        <div>ActionPLM helps industries to manage a product's whole life cycle, from ideation to completion, including planning, engineering, design, production, integration, marketing, and services.</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div>
                                        <img src={ion_shapesblue} alt='icon' />
                                        <div>This enables businesses to increase productivity and efficiency while also enhancing manufacturing quality.</div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div className='content_inner contentRight'>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div style={{ pading: "2rem" }}>
                                        <img src={ion_shapespink} alt='icon' />
                                        <div className='pe-5'>The process industries are related to formulas and manufacturing recipes.</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div>
                                        <img src={ion_shapespink} alt='icon' />
                                        <div className='pe-5'>It is widely used in the food, beverage, chemical, pharmaceutical, nutraceutical, consumer packaged goods, cannabis, and biotechnology industries.</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div>
                                        <img src={ion_shapespink} alt='icon' />
                                        <div className='pe-5'>To address all the challenges ActionPLM delivers process PLM for handling all these variables.</div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
                <div className='ActionPLm_inner_mobile'>
                    <div className='subText-area'>
                        <p className='subbulid_text'>ActionPLM delivers a better product to our clients with our unique ideas and implementation.</p>
                    </div>
                    <div className='accordingAPI'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='accordion-item-frist'>
                                <div className='discreteIndustry'>Discrete Industry</div>
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body>
                                    <div className='content_inner'>
                                        <ListGroup>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapesblue} alt='icon' />
                                                    <div>The discrete industry refers to the production of entire things that can be easily handled, numbered,or viewed.</div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapesblue} alt='icon' />
                                                    <div>ActionPLM helps industries to manage a product's whole life cycle, from ideation to completion, including planning, engineering, design, production, integration, marketing, and services.</div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapesblue} alt='icon' />
                                                    <div>This enables businesses to increase productivity and efficiency while also enhancing manufacturing quality.</div>
                                                </div>
                                            </ListGroup.Item>
                                            {/*for adjust height*/}
                                            <ListGroup.Item>
                                                <div>
                                                    {/* <img src={ion_shapespink} alt='icon' /> */}
                                                    <div></div>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className="accordion-item-second processIndustry">
                                <div className='ProcessIndustry'>Process Industry</div>
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body>
                                    <div className='content_inner'>
                                        <ListGroup>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapespink} alt='icon' />
                                                    <div>The process industries are related to formulas and manufacturing recipes.</div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapespink} alt='icon' />
                                                    <div>It is widely used in the food, beverage, chemical, pharmaceutical, nutraceutical, consumer packaged goods, cannabis, and biotechnology industries.</div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div>
                                                    <img src={ion_shapespink} alt='icon' />
                                                    <div>To address all the challenges ActionPLM delivers process PLM for handling all these variables.</div>
                                                </div>
                                            </ListGroup.Item>
                                            {/*for adjust height*/}

                                            <ListGroup.Item>
                                                <div>
                                                    {/* <img src={ion_shapespink} alt='icon' /> */}
                                                    <div></div>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ActionPLM